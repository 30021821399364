<div class="header">
  <div class="site-general">
    <button class="m-screens menu-btn" mat-icon-button [matMenuTriggerFor]="menu">
      <mat-icon>more_vert</mat-icon>
    </button>
    <div class="site-name-search-bar">
      <h1 class="site-name">Healthcare Resources for Women with SCI</h1>
    </div>
    <img src="'../../../../../assets/img/here.png" class="logo" />
  </div>
  <nav class="nav-tabs l-screens">
    <span *ngFor="let navItem of navItems">
      <button *ngIf="!navItem.menuItems" [ngClass]="{
          active: active === navItem.navItemName.split(' ').join('')
        }" routerLink="/{{ navItem.navItemName.split(' ').join('') }}">
        {{ navItem.navItemName }}
      </button>
      <button *ngIf="navItem.menuItems" [matMenuTriggerFor]="belowMenu" [ngClass]="{
          active: active === navItem.navItemName.split(' ').join('')
        }" (menuOpened)="navItem.arrowUp = !navItem.arrowUp" (menuClosed)="navItem.arrowUp = !navItem.arrowUp">
        {{ navItem.navItemName
        }}<i [ngClass]="navItem.arrowUp ? 'arrowUp' : 'arrowDown'"></i>
      </button>
      <mat-menu #belowMenu="matMenu" yPosition="below">
        <button [ngClass]="activeMenuOpt === menuItem.split(' ').join('') ? 'activeOpt' : 'inactiveOpt'
        " *ngFor="let menuItem of navItem.menuItems" mat-menu-item [routerLink]="[
        '/' + navItem.navItemName.split(' ').join(''),
        transformString(menuItem)
      ]">
          {{ menuItem }}
        </button>
      </mat-menu>
    </span>
    <div class="search-bar">
      <app-search-bar></app-search-bar>
    </div>
  </nav>

  <div class="m-screens">
    <app-search-bar></app-search-bar>
  </div>
</div>


<mat-menu #menu="matMenu" class="m-screens">
  <div *ngFor="let navItem of navItems">
    <button mat-menu-item *ngIf="!navItem.menuItems" [ngClass]="{
    active: active === navItem.navItemName.split(' ').join('')
  }" routerLink="/{{ navItem.navItemName.split(' ').join('') }}">
      {{ navItem.navItemName }}
    </button>
    <button mat-menu-item *ngIf="navItem.menuItems" [matMenuTriggerFor]="belowMenu" [ngClass]="{
    active: active === navItem.navItemName.split(' ').join('')
  }" (menuOpened)="navItem.arrowUp = !navItem.arrowUp" (menuClosed)="navItem.arrowUp = !navItem.arrowUp">
      {{ navItem.navItemName
      }}<i [ngClass]="navItem.arrowUp ? 'arrowUp' : 'arrowDown'"></i>
    </button>

    <mat-menu #belowMenu="matMenu" yPosition="below">
      <button mat-menu-item [ngClass]="activeMenuOpt === menuItem.split(' ').join('') ? 'activeOpt' : 'inactiveOpt'
  " *ngFor="let menuItem of navItem.menuItems" mat-menu-item [routerLink]="[
  '/' + navItem.navItemName.split(' ').join(''),
  transformString(menuItem)
]">
        {{ menuItem }}
      </button>
    </mat-menu>
  </div>
</mat-menu>


<!-- 
<mat-sidenav-container #menu="matMenu">
  <mat-sidenav-content>Main</mat-sidenav-content>
  <mat-sidenav-content>Main 2</mat-sidenav-content>
</mat-sidenav-container> -->
<!-- <mat-drawer-container class="example-container">
  <mat-drawer mode="side" opened>Drawer content</mat-drawer>
  <mat-drawer-content>Main content</mat-drawer-content>
</mat-drawer-container> -->