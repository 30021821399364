export const resources = {
  'Access': [
    {
      topic: 'Access',
      img: 'icons-wheelchair',
      longDescription: [
        'Access to healthcare is a broad and complex topic that has varying interpretations and definitions <cite><a href="https://equityhealthj.biomedcentral.com/articles/10.1186/1475-9276-12-18" rel="noreferrer noopener" target="_blank">1</a></cite>. For the purpose of this resource directory, we have divided access to healthcare into four overarching categories. This includes:',
        '<ul><li>physical or environmental access to healthcare settings and procedures (e.g., buildings, rooms, exam tables)</li><li>healthcare information access (e.g., current information on best practices)</li><li>equipment access (e.g., information and availability of adaptive devices for mobility and daily living)</li><li>cost (e.g., financial burden, insurance).</li></ul>'
      ],
    }
  ],
  'Advocacy': [
    {
      topic: 'Advocacy',
      img: 'icons-megaphone',
      longDescription: [
        'It is important for women with SCI to be strong self-advocates by speaking up for and taking action to promote, protect, and defend their interests and rights. This is true across all aspects of life and across the full spectrum of human needs, but it can be particularly important to ensuring health and healthcare needs are met. Advocating for your needs and rights can be challenging, but there are agencies and resources that can help. The links listed below on the HeRe for Women website provide information, tools, and resources to help people advocate for themselves and/or others. In addition, there are resources to support advocacy efforts to achieve systemic change in laws, policies, and practices that advance the interests of women with SCI.'
      ]
    }],
  'BladderBowel': [
    {
      introImg: '',
      topic: 'Bladder',
      img: 'icons-toilet',
      longDescription: [
        '<h4><strong>Bladder\n</strong></h4> Most women have difficulty controlling urine after spinal cord injury. A spinal cord injury can limit the ability to urinate or know when the bladder is full <cite><a href="https://msktc.org/lib/docs/Factsheets/SCI_Bladder_Health.pdf" rel="noreferrer noopener" target="_blank">1</a></cite>. The bladder may contract uncontrollably or become too full and cause incontinence (leaking/accidents).',
        'It is important to find a bladder management program with your doctor that works for you and your lifestyle, that limits infections (like UTIs), and that maintains kidney function. There are both surgical and non-surgical methods of bladder management. The method you and your doctor choose should be based on your lifestyle, motor skills, medical history and preferences.',
        'An effective bladder management program is important for your physical health, but it can be just as important for your psychological health and quality of life. The resources on this website provide information and tools to help you address your concerns with your healthcare provider and find a bladder management program that works best for you.',
      ]
    },
    {
      topic: 'Bowel',
      longDescription: [
        '<h4><strong>Bowel\n</strong></h4>Bowel complications are common among individuals with spinal cord injury. When the nerves that allow a person to control their bowel are damaged, the result is a neurogenic bowel. This can lead to bowel incontinence (leaking/accidents), constipation, or abdomen pain <cite><a href="https://msktc.org/sci/factsheets/Bowel_Function" target="_blank">2</a></cite>. Finding an effective bowel program method with your physician and caregiver is important in preventing further medical complications and enhancing your quality of life. The resources listed provide information about neurogenic bowel and strategies that may help you develop a bowel program that works for you.'
      ]
    },
  ],
  'Gynecology': [
    {
      topic: 'Gynecology',
      img: 'icons-gynecology',
      longDescription: [
        'Gynecologic health refers to the health of the sexual and reproductive system of female bodies. Representing less than 25% of all people with spinal cord injury (SCI), women with SCI may find it more difficult to have their needs addressed and their questions answered about their gynecologic health.',
        "Your gynecologic healthcare needs differ depending on your stage in life, but across the life span, gynecologic healthcare includes health screenings such as PAP tests and mammograms. Research has shown that women with SCI are less likely to receive such health screenings than women without disabilities<cite><a rel='noreferrer noopener' target='_blank' href='https://doi.org/10.1080/10790268.2016.1153293'>1</a></cite>. It is important for women with SCI to understand screening guidelines and recommendations and advocate to receive routine accessible screening services.",
        'Gynecologic health encompasses menstruation and menstrual management; sexuality and sexual function; contraception; pregnancy, delivery, and post-partum changes; and menopause.  Women with SCI have unique needs with regard to all of these aspects of gynecologic health. Resources on the HeRe for Women with SCI website address each of these aspects of gynecologic health. ',
        'While these topics may be uncomfortable for some women to discuss, we encourage you to discuss your gynecologic health and any gynecologic concerns with your healthcare provider. We recognize that many women will not have access to a gynecologist who is knowledgeable about SCI and experienced in treating women with SCI. You may find it necessary to help educate your doctor about the impact of SCI on your gynecologic health. We hope that this web resource will help prepare you for such conversations. '
      ],
    }
  ],
  'MentalHealth': [
    {
      topic: 'Mental Health',
      img: 'icons-mental-health',
      longDescription: [
        'Mental health includes our emotional, psychological, and social well-being. It is critical to our overall health and well-being and affects all aspects of our lives, including how we think, feel, and behave <cite class="cite"><a class="cite-a" href="https://www.cdc.gov/mentalhealth/learn/index.html" rel="noreferrer noopener" target="_blank">1</a></cite>. Positive mental health can help us more effectively cope with life stressors, maintain healthy relationships, take care of ourselves and our needs, be engaged with others in our communities, and live lives that are meaningful and fulfilling. There are things you can do to help promote positive mental health. See some of the resources on the HeRe for Women with SCI website for suggestions.',
        'According to the National Institute of Mental Health, mental health problems or mental illness affects about one in five adults in the United States. Only about half of people with mental illness receive treatment <cite><a href="https://www.nimh.nih.gov/health/statistics/index.shtml" rel="noreferrer noopener" target="_blank">2</a></cite>. Mental health problems cover a wide range of conditions, such as depression, anxiety, and substance abuse problems. There is evidence that mental health issues, such as depression, are more common among people with SCI.',
        'It is important to recognize that mental health problems are not inevitable. Most women with SCI do not experience mental illness or significant mental health problems. If you are concerned about your mental health or the mental health of someone close to you, there are resources available. Please see the resources listed on this website, and be sure to talk to your doctor.',
        '<strong>If you are in danger of harming yourself now, call 911, 988, the 24-hour National Crisis Hotline at 800-273-TALK (8255), or go to your local emergency room.</strong>'
      ]
    }
  ]
}

export const filterOptions = {
  'Access': {
    topic: 'Access',
    img: 'icons-wheelchair.png',
    headerImg: 'ACCESS.jpg'

  }, 'Advocacy': {
    topic: 'Advocacy',
    img: 'icons-megaphone.png',
    headerImg: 'ADVOCACY.jpg'
  }, 'Bowel & Bladder': {
    topic: 'Bladder & Bowel',
    img: 'icons-toilet.png',
    headerImg: 'BLADDERBOWEL.jpg'
  }
  , 'Gynecology': {
    topic: 'Gynecology',
    img: 'icons-gynecology.png',
    headerImg: 'OB_GYN.jpg'
  }, 'Mental Health': {
    topic: 'Mental Health',
    img: 'icons-mental-health.png',
    headerImg: 'MENTALHEALTH.jpg'
  }
};