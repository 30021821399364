import { Component, OnInit } from '@angular/core';
import { ArticlesResultService } from 'src/app/components/articles-result/articles-result.service';

@Component({
  selector: 'app-search-results',
  templateUrl: './search-results.component.html',
  styleUrls: ['./search-results.component.scss']
})
export class SearchResultsComponent implements OnInit {
  articles: any[] = [];
  constructor(private articleResultService: ArticlesResultService) {
    this.articleResultService.articles.subscribe(
      articles => (this.articles = articles)
    );
  }

  ngOnInit(): void {}
}
