<mat-card class="card physicians hover-overlay hover-zoom hover-shadow ripple" *ngIf="type==='physicians'"
  (click)="onClickCard($event)">
  <mat-card-title>
    <h6><a href="#" (click)="openWebsite($event, website)" target="_blank">{{ hospitalName}}</a></h6>
  </mat-card-title>
  <mat-card-subtitle>{{ Speciality }}</mat-card-subtitle>
  <mat-card-content>
    <div *ngIf="doctorName">
      <fa-icon [icon]="iconDoctor"></fa-icon>{{ doctorName }}
    </div>
    <div>
      <fa-icon [icon]="iconPhone"></fa-icon>{{ phone }}
    </div>
    <div><i class='fas fa-location-pin'></i>
      <fa-icon [icon]="iconAddress"></fa-icon>{{ address }}, {{city}} {{zip}}
    </div>
  </mat-card-content>


  <div class="mask" [ngClass]="{'active': isActive}" style="
      background: linear-gradient(
        45deg,
        rgba(29, 236, 197, 0.2),
        rgba(91, 14, 214, 0.2) 100%
      );"></div>

</mat-card>