import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-sort-dropdown',
  templateUrl: './sort-dropdown.component.html',
  styleUrls: ['./sort-dropdown.component.scss']
})
export class SortDropdownComponent implements OnInit {
  // showDropdown: boolean = false;
  originalOPtions: string[] = [
    'A-Z',
    'Z-A',
    'Most Liked',
    'Most relevant',
    'Newest',
    'Oldest'
  ];
  // constructor() {}

  // ngOnInit(): void {}

  @Input() labelKey = 'label';
  @Input() idKey = 'id';
  @Input() options = [];
  @Input() model;

  ngOnInit() {
    if (this.model !== undefined) {
      this.model = this.options.find(
        currentOption => currentOption[this.idKey] === this.model
      );
    }
  }

  get label() {
    return this.model ? this.model[this.labelKey] : 'Select...';
  }

  // form = new FormGroup({
  //   website: new FormControl('', Validators.required)
  // });

  // get f(){
  //   return this.form.controls;
  // }

  // changeSortOption(e) {
  //   console.log(e.target.value);
  // }
}
