<!-- <form ngbDropdown role="group" (clickElsewhere)="isShowDropdown = false" aria-label="Button group with nested dropdown"
  class="search-bar-container">
  <div (click)="isShowDropdown = !isShowDropdown" ngbDropdownToggle class="search-wrapper">
    <input autocomplete="off" type="text" class="search-form-control" id="resource" [formControl]="SearchForm"
      placeholder="Search Resources" #input />
    <fa-icon class="search-icon" [icon]="searchIcon" size="lg"></fa-icon> -->
<!-- <div class="autocomplete-dropdown">
    <p *ngFor="let tag of tags">{{tag}}</p>
  </div> -->
<!-- <div class="dropdown-menu" ngbDropdownMenu>
      <span ngbDropdownItem *ngFor="let tag of tags" (click)="getArticles(tag); input.value=tag">
        {{ tag}}
      </span>
    </div>
  </div>
</form> -->

<form class="search-bar-container">
  <mat-form-field class="example-full-width" appearance="outline" [floatLabel]="'never'">

    <mat-label></mat-label>
      <mat-icon matPreffix>search</mat-icon>
      <input matInput aria-label="SearchForm" placeholder="Search Resources" [matAutocomplete]="auto" [formControl]="SearchForm">



    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectOption($event)" autoActiveFirstOption>
      <mat-option *ngFor="let tag of filteredTags | async" [value]="tag">
        <!-- <img class="example-option-img" aria-hidden [src]="state.flag" height="25"> -->
        <span>{{tag}}</span>
        <!-- <small>Population: {{state.population}}</small> -->
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</form>