<mat-card class="long-card">
  <mat-card-content>
    <section class="info">
      <mat-card-title>
        <h6><a [href]="articleLink" target="_blank" class="title">{{ articleName }}</a></h6>
      </mat-card-title>

      <div class="card-main-content">
        <div>
          <mat-card-subtitle>Published to HeRe on {{ publishedOn | date:'MM-dd-yyyy' }}</mat-card-subtitle>
          <p class="description">{{ description }}</p>
        </div>
        <section class="review" (click)="openDialog(content)">
          <div class="rating-d">
            <section class="stars">
              <img class="star" *ngFor="let star of filledStars" src="/assets/img/star.svg" alt="star icon" />
              <img *ngIf="reviewAvg % 1 !==0" src="/assets/img/half-star.svg" alt="star icon" />
              <img class="star" *ngFor="let star of emptyStars" src="/assets/img/empty-star.svg" alt="star icon" />
            </section>
            <span>{{reviewAvg.toFixed(2)}}/{{ratingTotal}}</span>
          </div>

          <button class="add-review-button">
            RATE RESOURCE
          </button>
          <a class="comments-link" *ngIf="comments.length" (click)="showComment($event)">
            {{ isShowComment ? 'Hide Reviews' : 'View Reviews' }}
          </a>
        </section>
      </div>
    </section>

  </mat-card-content>
  <mat-card-footer>
    <div class="tag-container">
      <div class="tag" *ngFor="let tag of Tags" (click)="handleTagClick($event)">{{ tag.tagName | uppercase }}</div>
    </div>

    <div *ngIf="isShowComment" class="comments">
      <div class="comments-title">Reviews</div>
      <p *ngFor="let review of comments">
        {{ review }}
      </p>
    </div>
  </mat-card-footer>
</mat-card>

<ng-template #content let-modal>
  <div class="rating-container">
    <h5>We value your feedback. Please rate and review this resource.</h5>
    <fieldset class="rating">
      <ngb-rating [(rate)]="currentRate" [max]="5" (hover)="hovered=$event" (leave)="hovered=0" [readonly]="readonly">
        <ng-template let-fill="fill" let-index="index">
          <span class="star" [class.filled]="fill === 100">&#9733;</span>
        </ng-template>
      </ngb-rating>
    </fieldset>
    <button class="btn btn-link" (click)="isAddReview = !isAddReview" *ngIf="!isAddReview">
      {{!isAddReview ? 'Add a review' : ''}}
    </button>
    <form [formGroup]="ReviewField">
      <textarea *ngIf="isAddReview" class="comment" placeholder="Write a review (Optional)"
        formControlName="review"></textarea>
    </form>
    <div class="rate-buttons">
      <button class="btn btn-light" (click)="modalRef.close()">
        No, Later
      </button>
      <button class="btn btn-primary" [disabled]="!currentRate" (click)="onRate()">
        Rate
      </button>

    </div>
  </div>
</ng-template>