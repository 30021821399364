import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import {
  faMapPin,
  faPhone,
  faInbox,
  faEnvelope
} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {
  ContactUsForm: FormGroup;
  dropdownOptions: object[] = [
    { key: 1, value: 'Question' },
    { key: 2, value: 'Resource suggestion' },
    { key: 3, value: 'Comment' },
    { key: 4, value: 'Site Problem' }
  ];

  contactCards = [
    { contactType: '1333 Moursund St, Houston, TX 77030', img: faMapPin, actionType: "map" },
    { contactType: '713-797-5972', img: faPhone, actionType: "phone" },
    { contactType: `HeReforWomen@ memorialhermann.org`, img: faEnvelope, actionType: "email" },
  ];
  constructor() {
    this.ContactUsForm = new FormGroup({
      name: new FormControl(null),
      subject: new FormControl('Comment'),
      email: new FormControl(null, [Validators.required, Validators.email]),
      message: new FormControl(null)
    });
  }

  ngOnInit(): void { }

  onSelectSubject(subject) {
    this.ContactUsForm.controls.subject.setValue(subject.value);
  }

  onCardClick($event, actionType) {
    $event.preventDefault();
    if (actionType === "map") {
      window.open("https://www.google.com/maps/dir//TIRR+Memorial+Hermann,+1333+Moursund+St,+Houston,+TX+77030/", "_blank");
      return;
    }
    if (actionType === "phone") {
      window.open("tel:7131975972", "_self");
      return;
    }

    window.open("mailto:HeReforWomen@memorialhermann.org?subject=HeRe - Contact Us", "_self");
  }
}
