import { Component, OnInit, Input, Inject, Output, EventEmitter } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RatingDialogComponent } from '../rating-dialog/rating-dialog.component';

export interface Card {
  articleName: string;
  description: string;
  reviewAvg: number;
  Tags: string[];
  createdAt: string;
  publishedOn: number,
  reviewCount: number;
  articleLink: string;
}

import { faStar } from '@fortawesome/free-regular-svg-icons';
import { ResoucesService } from 'src/app/services/resouces.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormGroup } from '@angular/forms';
import { ViewportScroller } from '@angular/common';

@Component({
  selector: 'app-long-card',
  templateUrl: './long-card.component.html',
  styleUrls: ['./long-card.component.scss']
})
export class LongCardComponent implements OnInit {
  ReviewField: FormGroup;
  @Input() articleName: string;
  @Input() description: string;
  @Input() reviewAvg: number = 0;
  @Input() Tags: string[];
  @Input() createdAt: any;
  @Input() comments: any[] = [];
  @Input() articleLink: string;
  @Input() articleId: number;
  @Input() ratingTotal: number = 0;
  @Input() publishedOn: any;

  @Output() tagEvent: EventEmitter<any> = new EventEmitter();

  iconStar = faStar;
  isShowComment: boolean = false;
  filledStars: number[] = [];
  emptyStars: number[] = [];
  currentRate: number = 0;
  modalRef: any;
  isAddReview: boolean = false;

  constructor(private modalService: NgbModal, private resourceService: ResoucesService, private scroller: ViewportScroller) {
    this.ReviewField = new FormGroup({
      review: new FormControl(null)
    });
  }

  ngOnInit(): void {
    this.filledStars = new Array(Math.floor(this.reviewAvg)).fill(1);
    this.createdAt = new Date(this.createdAt * 1000).toDateString();
    this.emptyStars = new Array(5 - this.filledStars.length - (this.reviewAvg % 1 !== 0 ? 1 : 0)).fill(1);
  }

  openDialog(content): void {
    this.modalRef = this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' });
  }

  async onRate() {
    this.modalRef.close();
    let ratingBody = {
      rating: this.currentRate,
      comment: this.ReviewField.value.review,
      ratingId: this.articleId
    }

    const ratingRes = await this.resourceService.setRating(ratingBody);
    if (ratingRes) {
      this.reviewAvg = ratingRes.ratingAvg;
      this.comments = ratingRes.ratings.reduce((acc, curr) => {
        if (curr.comment) {
          acc.push(curr.comment);
        }
        return acc;
      }, []);
      this.ratingTotal = ratingRes.ratingCount;
    }

    this.filledStars = new Array(Math.floor(this.reviewAvg)).fill(1);
    this.emptyStars = new Array(5 - this.filledStars.length - (this.reviewAvg % 1 !== 0 ? 1 : 0)).fill(1);

    this.currentRate = 0;
  }

  showComment($event) {
    $event.stopPropagation();
    this.isShowComment = !this.isShowComment;
  }

  handleTagClick($e) {
    $e.stopPropagation();
    this.scroller.scrollToAnchor("filter-elem");

    this.tagEvent.emit();
  }
}
