import { Component } from '@angular/core';
import { resources } from 'mock-data';
import { FormControl } from '@angular/forms';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router';
import { ArticlesResultService } from '../articles-result/articles-result.service';
import { ResoucesService } from 'src/app/services/resouces.service';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { MatOption } from '@angular/material/core';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';


@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss']
})
export class SearchBarComponent {
  SearchForm = new FormControl();
  filteredTags: Observable<any[]>;
  option: MatOption;
  allArticles: any[] = [];


  resources = resources;
  selectedArticles: any[] = [];
  searchIcon = faSearch;
  isSearch: boolean = false;
  tagSearchResults: string[];
  searchFunc: any;
  tags: string[] = [];
  searchResults: any;
  isShowDropdown: boolean = false;



  //@ViewChild('input', { static: true }) input: ElementRef;

  constructor(
    private articleResultsService: ArticlesResultService,
    private router: Router,
    private resourceService: ResoucesService
  ) {
    this.resourceService.getTags().subscribe(tags => {
      this.tags = ["all resources", ...tags.sort((a, b) => a.localeCompare(b))];
      //this.searchResults = new SearchFunc(this.tags);
      this.filteredTags = this.SearchForm.valueChanges.pipe(
        startWith(''),
        map(tag => tag ? this._filterTags(tag) : this.tags.slice())
      );
    });

    this.resourceService.getAllArticles().subscribe(articles => {
      this.allArticles = articles;
    });
  }

  private _filterTags(value: string): any[] {
    const filterValue = value.toLocaleLowerCase();
    return this.tags.filter(tag => tag.toLocaleLowerCase().indexOf(filterValue) === 0);
  }

  selectOption(e: MatAutocompleteSelectedEvent) {
    const selectedTag = e.option.value;
    this.articleResultsService.searchText.next(selectedTag);
    if (this.router.url !== '/SearchResults')
      this.router.navigate(['/SearchResults']);

    if (selectedTag === "all resources") {
      this.articleResultsService.articles.next(this.allArticles);
      return
    }

    const filteredArticles = this.allArticles.filter(article => article.Tags.some(tag => tag.tagName === selectedTag));
    this.articleResultsService.articles.next(filteredArticles);
  }

  ngOnInit(): void {
  }
}
