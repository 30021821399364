<swiper [loop]="true" effect="fade" [preloadImages]="true" [autoHeight]="true" [slidesPerView]="2" [spaceBetween]="50" [allowTouchMove]="true"
  [zoom]="true" [autoplay]="{ delay: 6000, disableOnInteraction: false }"
  [pagination]="{ clickable: true, progressbarOpposite: true}" [fadeEffect]="{crossFade: true}" [navigation]="true"
  [autoplay]="{ pauseOnMouseEnter: true, disableOnInteraction: false }">

  <ng-template data-swiper-autoplay="8000" [zoom]="true" *ngFor="let img of images; index as i" swiperSlide>
    <img [src]="img" alt="Carousel image number {{i + 1}}" />
  </ng-template>
  <div slot="container-end" class="container-end"></div>
  <!-- <span slot="wrapper-start">Wrapper Start</span>
  <span slot="wrapper-end">Wrapper End</span> -->
</swiper>