<div class="network-container" [ngSwitch]="pageType">
  <app-join-newsletter-form id="JoinUs" *ngSwitchCase="'Newsletters'"></app-join-newsletter-form>
  <mat-card class="joinUsView" *ngSwitchCase="'AboutNetwork'">
    <div class="aboutNetworks firstBlock" id="AboutNetwork">
      <div class="subBlock">
        <h2 class="title" id="tt">About our Network</h2>
        <img class="divider" src="../../../assets/img/divider.png" />
        <h4><strong>Our Mission</strong></h4>
        <p>
          The mission of the Women’s Health after SCI Network (WHSCI Network) is to share women’s health resources in an
          effort to reduce the barriers women with SCI face finding and learning about healthcare resources that meet
          their specific needs, while helping the community identify gaps in resources and raising awareness. The HeRe
          WHSCI Network is committed to communicating and sharing links to valuable information that can be accessed in
          one
          directory, the <strong>Healthcare Resources for Women with SCI (HeRe for Women)</strong> website.
        </p>
        <p>
          Women with SCI face multiple healthcare concerns that can impact their quality of life. This has historically
          been complicated by the lack of healthcare information available for women with SCI as well as increased
          barriers women face both finding and accessing this information. With a mission to bridge the gap, the WHSCI
          Network will communicate, collect, and share the information that is available in one online directory. Links
          to
          identified resources will be added to the Here for Women website, which provides an accessible platform to
          view
          and share high-quality healthcare resources and educational information for women with SCI. This directory is
          not intended to replace medical advice, and we recommend discussing any information of interest with a
          healthcare
          professional.
        </p>
      </div>
      <div class="subBlock">
        <h4><strong>Who Should Join?</strong></h4>
        <p>
          Women, healthcare providers, researchers, caregivers, family and friends, and anyone else interested in the
          health
          and wellness of women with SCI are welcome to join.
        </p>
      </div>
    </div>

    <section class="import-info-card">
      <div class="import-info-body">
        <h4><strong>Benefits of network membership include</strong></h4>
        <div>
          <mat-card class="mat-card-sp">
            <p> Biannual newsletters updating members on new healthcare information
              that is available on important topics for women with SCI, including
              new listings on the HeRe website</p>
            <em>
              <p>These newsletters will also include requests for new information
                identified or recently developed that would be ready and available
                for public sharing and dissemination.</p>
            </em>

          </mat-card>
          <mat-card class="mat-card-sp">
            <p> Free listing of links to resources allowing easy dissemination of
              appropriate resources on the HeRe website.
          </mat-card>
          <mat-card class="mat-card-sp">
            <p> Your name and affiliation listed on the website as a member of the HeRe WHSCI Network.</p>
            <ul>
              <li>
                To maintain privacy, we plan to include Individual member’s names and info
                as follows (only): Heather
                Taylor, Texas, USA, TIRR Memorial Hermann and UTHealth.
              </li>
              <li>
                Your contact information will be kept private. Email will only be used to provide the
                bi-annual updates
                and communication.
              </li>
            </ul>
          </mat-card>
        </div>
      </div>
    </section>
    <div class="aboutNetworks">
      <div>
        <h4><strong>How to Join</strong></h4>
        <p>Membership is free, and you can join the HeRe WHSCI Network by filling out the online application, <a
            href="#" [routerLink]="'/Network/JoinUs'">here</a>.</p>
        <p>You can stop membership and communication at any time by sending an email to
          <a
            href="mailto:HeReforWomen@memorialhermann.org?subject=HeRe - How to Join">HeReforWomen@memorialhermann.org</a>.
        </p>
      </div>
    </div>

    <section class="subBlock import-info-card highlight-bg">
      <p class="import-info-body"> We gratefully acknowledge the support of
        <a href="https://chnfoundation.org/" target="_blank">The Craig H. Neilsen Foundation</a>
        for its generous infrastructure grant and the support from the Women’s
        Health Task Force from ACRM and the Women’s Health Special Interest
        Group from the SCI Model Systems funded by NIDILRR.
      </p>
    </section>
    <div class="block-wrapper">
      <div class="secondBlock">
        <h4><strong>Project Leaders</strong></h4>
        <p>Director: Heather B. Taylor, Ph.D.</p>
        <p>Susan Robinson-Whelen, Ph.D.</p>
        <p>Thoman N. Bryce, M.D.</p>
        <p>Sarah Hoffman, B.A.</p>
        <p>Andrew Delgado, M.S.</p>
      </div>
</div>
      <div class="aboutNetworks thirdBlock">
        <h4><strong>Network Members</strong></h4>
        <p>
          Network Members include consumers with lived experience with SCI and professionals who are interested in their
          care and empowerment.
        </p>
        <p>
          This includes members from the SCI Model Systems Women’s Health Task Force, ACRM SCI-iSIG Women’s Health Task
          Force, clinicians, family and friends. Please join our growing network <a
          href="#" [routerLink]="'/Network/JoinUs'">here</a>.
        </p>
      </div>
    
  </mat-card>

  <mat-card class="joinUsView" *ngSwitchCase="'JoinUs'">
    <div class="form-wrapper">
      <app-join-newsletter-form></app-join-newsletter-form>
    </div>
  </mat-card>

  <div class="form-wrapper" id="Share" *ngSwitchCase="'Share'">
    <app-share-resources-form></app-share-resources-form>
  </div>
</div>