<footer>
    <mat-card class="contact-info link-list">
        <mat-list role="list" [routerLink]="'/ContactUs'" [fragment]="'contactusPage'"> <button mat-button>CONTACT
                US</button>
            <mat-list-item role="listitem" class="unsetCursor">
                <fa-icon [icon]="iconEmail"></fa-icon>
                <a
                    href="mailto:HeReforWomen@memorialhermann.org?subject=HeRe - How to Join">HeReforWomen@memorialhermann.org</a>
            </mat-list-item>
            <mat-list-item role="listitem" class="unsetCursor">
                <fa-icon [icon]="iconPhone"></fa-icon>713-797-5972
            </mat-list-item>
            <mat-list-item role="listitem" class="unsetCursor">
                <fa-icon [icon]="iconAddress"></fa-icon>1333 Moursund St, Houston, TX 77030
            </mat-list-item>
        </mat-list>
    </mat-card>

    <mat-list role="list" class="link-list">
        <mat-list-item class="main-link" role="listitem" [routerLink]="'/Home'" [fragment]="'homePage'">Home
        </mat-list-item>
        <mat-list-item class="main-link" role="listitem" [routerLink]="'/AboutUs/AbouttheSite'" [fragment]="'AbouttheSite'">
            About</mat-list-item>
        <mat-list-item role="listitem" [routerLink]="'/AboutUs/AbouttheSite'" [fragment]="'AbouttheSite'">About the Site
        </mat-list-item>
        <mat-list-item role="listitem" [routerLink]="'/AboutUs/MeettheTeam'" [fragment]="'MeettheTeam'">About the Team
        </mat-list-item>
        <mat-list-item role="listitem" [routerLink]="'/AboutUs/SCIDR'" [fragment]="'MeettheTeam'">About SCIDR
        </mat-list-item>
    </mat-list>
    <mat-list role="list" class="link-list">
        <h6 class="main-link">Resources</h6>
        <mat-list-item role="listitem" [routerLink]="'/Resources/Access'">Access</mat-list-item>
        <mat-list-item role="listitem" [routerLink]="'/Resources/Advocacy'">Advocacy
        </mat-list-item>
        <mat-list-item role="listitem" [routerLink]="'/Resources/BladderBowel'">Bladder &
            Bowel</mat-list-item>
        <mat-list-item role="listitem" [routerLink]="'/Resources/Gynecology'">Gynecology</mat-list-item>
        <mat-list-item role="listitem" [routerLink]="'/Resources/MentalHealth'">Mental
            Health</mat-list-item>
    </mat-list>
    <mat-list role="list" class="link-list">
        <h6 class="main-link">Healthcare Providers</h6>
        <mat-list-item role="listitem" [routerLink]="'/HealthcareProviders'" [fragment]="'AboutHealthcareResources'">
            About Healthcare Resources</mat-list-item>
        <mat-list-item role="listitem" [routerLink]="'/HealthcareProviders'"
            [fragment]="'HealthcareProvidersResources'">Healthcare Providers Resources</mat-list-item>
    </mat-list>
    <mat-list role="list" class="link-list">
        <h6 class="main-link">Network</h6>
        <mat-list-item role="listitem" [routerLink]="'/Network/AboutNetwork'">About Network
        </mat-list-item>
        <mat-list-item role="listitem" [routerLink]="'/Network/JoinUs'">Join Us</mat-list-item>
        <mat-list-item role="listitem" [routerLink]="'/Network/Share'">Share</mat-list-item>
        <mat-list-item role="listitem" [routerLink]="'/Network/Newsletters'">Newsletters</mat-list-item>
    </mat-list>

    <mat-list role="list" class="link-list">
        <h6 class="main-link">Disclaimers</h6>
        <mat-list-item role="listitem" (click)="openModal(content, 'websiteDisclaimer')">Website Disclaimer
        </mat-list-item>
        <mat-list-item role="listitem" (click)="openModal(content, 'affiliateDisclaimer')">Affiliate Disclaimer
        </mat-list-item>
        <mat-list-item role="listitem" (click)="openModal(content, 'resourcesDisclaimer')">Resources Disclaimer
        </mat-list-item>
    </mat-list>
</footer>

<ng-template #content let-modal>
    <div class="modal-container">
        <h5 [innerHTML]="modalContent.title"></h5>
        <div class="body-text" [innerHTML]="modalContent.body">
        </div>
    </div>
</ng-template>