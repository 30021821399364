import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

interface Topic {
  createdAt: string,
  id: number,
  longDescription: any,
  shortDescription: string,
  topicAlias: string,
  topicId: number,
  topicName: string,
  updatedAt: number,
  articles: Article[],
  Articles: Article[],
  Tags: string[],
  tags: string[],
  mediums: string[]
}

interface Article {
  articleLink: string,
  articleName: string,
  createdAt: string,
  description: string,
  id: 4
  publishedOn: number,
  ratingAvg: number,
  ratings: any[],
  updatedAt: string,
  ratingCount: number,
  comments?: string[],
  Tags: any[]
}

@Injectable({
  providedIn: 'root'
})
export class ResoucesService {

  isLoadingSearch = new BehaviorSubject<boolean>(false);

  constructor(private http: HttpClient) { }

  getArticles(): Promise<any> {
    const headers = new HttpHeaders()
      .set('content-type', 'application/json')
      .set('Access-Control-Allow-Origin', '*');
    return this.http.get('https://here-server.link/api/articles', { headers: headers }).toPromise()
  }

  getTopic(alias): Observable<Topic> {
    const headers = new HttpHeaders()
      .set('content-type', 'application/json')
      .set('Access-Control-Allow-Origin', '*');
    return this.http.get<Topic>(`https://here-server.link/api/topics/${alias}`, { headers: headers }).pipe(map(topic => {
      topic.longDescription = topic.longDescription.split('|');
      topic.tags = topic.Tags;
      topic.mediums = [];
      topic.articles = topic.Articles.map(article => {
        const comments = [];
        article.ratings.forEach(rating =>
          rating.comment && comments.push(rating.comment)
        );
        article.articleName = article.articleName.includes('undefined') ? article.articleName.replace('undefined', '') : article.articleName;
        article.comments = comments;
        article.ratingAvg = article.ratingAvg || 0;

        return article;
      });

      return topic;
    }))
  }

  setArticle(body): Promise<any> {
    const headers = new HttpHeaders()
      .set('content-type', 'application/json')
      .set('Access-Control-Allow-Origin', '*');
    return this.http.post(`https://here-server.link/api/articles`, body, { headers }).toPromise();

  }

  setTopic(body): Promise<any> {
    const headers = new HttpHeaders()
      .set('content-type', 'application/json')
      .set('Access-Control-Allow-Origin', '*');
    return this.http.post(`https://here-server.link/api/topics`, body, { headers }).toPromise();

  }

  setRating(body): Promise<any> {
    const headers = new HttpHeaders()
      .set('content-type', 'application/json')
      .set('Access-Control-Allow-Origin', '*');
    return this.http.post(`https://here-server.link/api/ratings`, body, { headers }).toPromise();
  }

  sendEmail(body): Promise<any> {
    const headers = new HttpHeaders()
      .set('content-type', 'application/json')
      .set('Access-Control-Allow-Origin', '*');
    return this.http.post(`https://here-server.link/api/emails`, body, { headers }).toPromise();
  }

  getArticle(id): Observable<any> {
    const headers = new HttpHeaders()
      .set('content-type', 'application/json')
      .set('Access-Control-Allow-Origin', '*');
    return this.http.get<any>(`https://here-server.link/api/articles/${id}`, { headers: headers }).pipe(map(article => {
      const comments = [];
      article.ratings = article.ratings.map(rating => {
        if(rating.comment) comments.push(rating.comment);
        article.articleName = article.articleName.includes('undefined') ? article.articleName.replace('undefined', '') : article.articleName;
        return rating;
      })

      article.comments = comments;
      return article;
    }))
  }

  getSearchResult(param): Observable<any> {
    const headers = new HttpHeaders()
      .set('content-type', 'application/json')
      .set('Access-Control-Allow-Origin', '*');
    return this.http.get<any>(`https://here-server.link/api/tags/search/${param}`, { headers: headers }).pipe(map(articles => {

      articles = articles.map(article => {
        const comments = [];
        article.ratings.forEach(rating =>
          rating.comment && comments.push(rating.comment)
        )

        article.articleName = article.articleName.includes('undefined') ? article.articleName.replace('undefined', '') : article.articleName;

        article.comments = comments;
        return article;
      });

      return articles;
    }))
  }

  getTags(): Observable<any> {
    const headers = new HttpHeaders()
      .set('content-type', 'application/json')
      .set('Access-Control-Allow-Origin', '*');
    return this.http.get<any>(`https://here-server.link/api/tags`, { headers: headers }).pipe(map(tags => {
      tags = tags.map(tag => tag.tagName);
      return tags
    }))
  }

  getAllArticles(): Observable<any> {
    const headers = new HttpHeaders()
      .set('content-type', 'application/json')
      .set('Access-Control-Allow-Origin', '*');
    return this.http.get<any>(`https://here-server.link/api/articles`, { headers: headers }).pipe(map(articles => {

      articles = articles.map(article => {
        const comments = [];
        article.ratings.forEach(rating =>
          rating.comment && comments.push(rating.comment)
        )

        article.articleName = article.articleName.includes('undefined') ? article.articleName.replace('undefined', '') : article.articleName;

        article.comments = comments;
        article.ratingAvg = article.ratingAvg || 0;
        return article;
      });

      return articles;
    }))
  }
}
