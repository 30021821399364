import { Component, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-shared-icon',
  templateUrl: './shared-icon.component.html',
  styleUrls: ['./shared-icon.component.scss']
})
export class SharedIconComponent implements OnInit {
  constructor(
    public matIconRegistry: MatIconRegistry,
    private domSanitizar: DomSanitizer
  ) {
    const svgIconList = ['star-icon'];

    svgIconList.forEach((icon: string) =>
      matIconRegistry.addSvgIcon(
        icon,
        domSanitizar.bypassSecurityTrustResourceUrl('../../../../src/assets')
      )
    );
  }

  ngOnInit(): void {}
}
