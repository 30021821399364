export const physiciansInfo = [
  {
    id: 1,
    HospitalName: "Spain Rehabilitation Center",
    Speciality: "Inpatient",
    Street: "1717 6th Avenue South",
    City: "Birmingham, AL",
    Zip: 35249,
    Lat: 33.52080,
    Lng: -86.80270,
    Phone: " 205-934-4131",
    Website:
      "https://www.uabmedicine.org/locations/spain-rehabilitation-center",
  },
  {
    id: 2,
    HospitalName: "Rancho Los Amigos National Rehabilitation Center",
    Speciality: "Inpatient",
    Street: "7601 East Imperial Highway",
    City: "Downey CA",
    Zip: 90242,
    Lat: 33.92312,
    Lng: -118.13908,
    Phone: "1-844-804-0055",
    Website: "https://dhs.lacounty.gov/rancho/",
  },
  {
    id: 3,
    HospitalName: "Santa Clara Valley Medical Center",
    Speciality: "Inpatient & Outpatient",
    Street: "751 S Bascom Ave",
    City: "San Jose, CA",
    Zip: 95128,
    Lat: 37.31628,
    Lng: -121.93639,
    Phone: "408-885-2100",
    Website:
      "https://www.scvmc.org/health-care-services/rehabilitation/spinal-cord-injury",
  },
  {
    id: 4,
    HospitalName: "Craig Hospital",
    Speciality: "Inpatient",
    Street: "3425 S. Clarkson St.",
    City: "Englewood, CO",
    Zip: 80113,
    Lat: 39.64137,
    Lng: -104.95459,
    Phone: "303-789-8000",
    Website: "https://craighospital.org/",
  },
  {
    id: 5,
    HospitalName: "Craig Hospital",
    Speciality: "Outpatient",
    Street: "3425 S. Clarkson St.",
    City: "Englewood, CO",
    Zip: 80113,
    Lat: 39.64137,
    Lng: -104.95459,
    Phone: "303-789-8474",
    Website:
      "https://craighospital.org/programs/outpatient-clinic/sci-outpatient-program",
  },
  {
    id: 6,
    HospitalName: "MedStar National Rehabilitation Network",
    Speciality: "Inpatient & Outpatient",
    Street: "102 Irving Street, NW",
    City: "Washington, DC",
    Zip: 20010,
    Lat: 38.93297,
    Lng: -77.03007,
    Phone: "202-877-1152",
    Website:
      "https://www.medstarnrh.org/our-services/spinal-cord-injury-rehabilitation-services/",
  },
  {
    id: 7,
    HospitalName: "UHealth Jackson Rehabilitation Care",
    Speciality: "Inpatient",
    Street: "1611 N.W. 12th Avenue",
    City: "Miami, FL",
    Zip: 33136,
    Lat: 25.78663,
    Lng: -80.20451,
    Phone: "305-585-4321",
    Website:
      "https://rehabilitation.jacksonhealth.org/services/spinal-cord-injuries/",
  },
  {
    id: 8,
    HospitalName: "Shepherd Center",
    Speciality: "Inpatient",
    Street: "2020 Peachtree Road NW",
    City: "Atlanta, GA",
    Zip: 30309,
    Lat: 33.79846,
    Lng: -84.38828,
    Phone: "404-352-2020",
    Website: "https://www.shepherd.org/patient-programs/spinal-cord-injury",
  },
  {
    id: 9,
    HospitalName: "Shirley Ryan AbilityLab",
    Speciality: "Inpatient",
    Street: "355 East Erie",
    City: "Chicago, IL ",
    Zip: 60611,
    Lat: 41.89570,
    Lng: -87.61356,
    Phone: "312-238-1000",
    Website: "https://www.sralab.org/conditions/spinal-cord-injury",
  },
  {
    id: 24,
    DoctorName: "Joshua R. Wellington, MD",
    HospitalName: "IU Health University Hospital Interventional & Advanced Pain Therapies",
    Speciality: "Physical Medicine & Rehabilitation, Pain Medicine",
    Street: "550 N University Blvd, Suite 2007",
    Lat: 39.520370,
    Lng: -84.383430,
    City: "Indianapolis, IN",
    Zip: "46202",
    Phone: "317-944-2891",
    Website: "https:\/\/iuhealth.org\/find-locations\/iu-health-university-hospital-interventional-and-advanced-pain-therapies-iu-health-university-hospital"
  },
  {
    id: 10,
    HospitalName: "Spaulding New England Regional Spinal Cord Injury Center",
    Speciality: "Inpatient",
    Street: "300 1st Ave",
    City: "Charlestown, MA",
    Zip: 2129,
    Lat: 42.38168,
    Lng: -71.06409,
    Phone: "617-952-5000",
    Website:
      "https://spauldingrehab.org/conditions-services/spinal-cord-injury-rehab",
  },
  {
    id: 11,
    HospitalName:
      "University of Minnesota Spinal Cord Injury Medicine Fellowship",
    Speciality: "Inpatient",
    Street: "420 Delaware Street SE MMC 297",
    City: "Minneapolis, MN",
    Zip: 55455,
    Lat: 44.97390,
    Lng: -93.23638,
    Phone: "612-625-2661",
    Website:
      "https://med.umn.edu/rehabmedicine/education-training/fellowships/spine-injury-medicine",
  },
  {
    id: 12,
    HospitalName: "Kessler Institute for Rehabilitation",
    Speciality: "Inpatient",
    Street: "1199 Pleasant Valley Way",
    City: "West Orange, NJ",
    Zip: 7052,
    Lat: 40.79136,
    Lng: -74.26299,
    Phone: "973-731-3600",
    Website:
      "https://www.kessler-rehab.com/conditions-and-services/spinal-cord-rehabilitation/",
  },
  {
    id: 13,
    HospitalName: "Mount Sinai Rehabilitation Center",
    Speciality: "Inpatient",
    Street: "1450 Madison Avenue at 99th Street ",
    City: "New York, NY",
    Zip: 10029,
    Lat: 40.79171,
    Lng: -73.94332,
    Phone: "212-241-5417",
    Website:
      "https://www.mountsinai.org/care/rehab-medicine/services/spinal-cord-injury",
  },
  {
    id: 14,
    HospitalName: "Mount Sinai Outpatient Therapy",
    Speciality: "Outpatient",
    Street: "5-17 E. 102nd Street",
    City: "New York, NY",
    Zip: 10029,
    Lat: 40.79171,
    Lng: -73.94332,
    Phone: "212-241-4477",
    Website:
      "https://www.mountsinai.org/care/rehab-medicine/services/spinal-cord-injury",
  },
  {
    id: 28,
    DoctorName: "Stephanie Cho, MD",
    HospitalName: "Spaulding Rehabilitation Hospital - Boston",
    Speciality: "Physical Medicine & Rehabilitation, Spinal Cord Injury",
    Street: "300 1st Avenue",
    Lat: 42.377043,
    Lng: -71.051023,
    City: "Charlestown MA",
    Zip: "02129",
    Phone: "(617) 952-6220",
    Website: "https:\/\/spauldingrehab.org\/locations\/spaulding-rehabilitation-hospital"
  },
  {
    id: 27,
    DoctorName: "Pier Boutin, MD",
    HospitalName: "Fairview Hospital",
    Speciality: "Orthopaedic Surgery",
    Street: "780 South Main Street",
    Lat: 42.179114,
    Lng: -73.363314,
    City: "Great Barrington, MA",
    Zip: "01230",
    Phone: "(413) 854-9753",
    Website: "https:\/\/www.berkshirehealthsystems.org\/Fairview"
  },
  {
    id: 15,
    HospitalName: "MetroHealth Rehabilitation Institute of Ohio",
    Speciality: "Inpatient & Outpatient",
    Street: "4229 Pearl Rd",
    City: "Cleveland, OH",
    Zip: 44109,
    Lat: 41.44465,
    Lng: -81.69578,
    Phone: "216-778-4414",
    Website:
      "https://www.metrohealth.org/spine-center/spinal-cord-injury-treatment",
  },
  {
    id: 16,
    HospitalName: "Dodd Hall Rehabilitation Services",
    Speciality: "Inpatient",
    Street: "480 Medical Center Drive",
    City: "Columbus, OH",
    Zip: 43210,
    Lat: 40.00468,
    Lng: -83.02779,
    Phone: "614-293-5275",
    Website:
      "https://wexnermedical.osu.edu/locations/dodd-rehabilitation-hospital",
  },
  {
    id: 17,
    HospitalName: "Martha Morehouse Outpatient Services",
    Speciality: "Outpatient",
    Street: "2050 Kenny Road",
    City: "Columbus, OH",
    Zip: 43221,
    Lat: 40.00468,
    Lng: -83.02779,
    Phone: "614-293-5084",
    Website:
      "https://wexnermedical.osu.edu/locations/martha-morehouse-outpatient-care",
  },
  {
    id: 33,
    HopitalName: "Providence Women's Clinic - St. Vincent",
    Speciality: "OB\/GYN",
    Street: "9135 SW Barnes Rd, Suite 761",
    Lat: 45.517427,
    Lng: -122.7378611,
    City: "Portland, OR",
    Zip: "97225",
    Phone: "(503) 215-8699",
    Website: "https:\/\/www.providence.org\/locations\/or\/st-vincent-medical-center\/womens-clinic"
  },
  {
    id: 25,
    DoctorName: "Steven S. Andersen, MD",
    HospitalName: "Providence Physiatry Clinic",
    Speciality: "Physical Medicine & Rehabilitation",
    Street: "5050 NE Hoyt St. #611",
    Lat: 45.526930,
    Lng: -122.610850,
    City: "Portland, OR",
    Zip: "97213",
    Phone: "(503) 215-8699",
    Website: "https:\/\/www.providence.org\/locations\/or\/providence-professional-plaza\/physiatry-clinic"
  },
  {
    id: 18,
    HospitalName: "Magee Rehabilitation Hospital",
    Speciality: "Inpatient",
    Street: "1513 Race Street",
    City: "Philadelphia, PA",
    Zip: 19102,
    Lat: 39.95267,
    Lng: -75.16521,
    Phone: "215-587-3000",
    Website:
      "https://mageerehab.jeffersonhealth.org/rehab-services/spinal-cord-injury/",
  },
  {

    id: 19,
    HospitalName:
      "University of Pittsburgh (UPMC) Rehabilitation Institute ",
    Speciality: "Inpatient",
    Street: "1400 Locust St.",
    City: "Pittsburgh, PA",
    Zip: 15219,
    Lat: 40.44332,
    Lng: -79.99734,
    Phone: "1-877-287-3422",
    Website:
      "https://www.upmc.com/services/rehab/rehab-institute/locations/mercy",
  },
  {
    id: 26,
    DoctorName: "John Horton, MD",
    HospitalName: "University of Pittsburgh Medical Center",
    Speciality: "Physical Medicine & Rehabilitation, Spinal Cord Injury Medicine ",
    Street: "1400 Locust Street \nSte G-103\n",
    Lat: 40.484590,
    Lng: -88.993900,
    City: "Pittsburgh, PA",
    Zip: "15210",
    Phone: "(412)-232-8901",
    Website: "https:\/\/www.upmc.com\/contact"
  },
  {
    id: 20,
    HospitalName: "UPMC Mercy",
    Speciality: "Outpatient",
    Street: "1400 Locust St.",
    City: "Pittsburgh, PA",
    Zip: 15219,
    Lat: 40.44332,
    Lng: -79.99734,
    Phone: "412-232-8901",
    Website:
      "https://www.upmc.com/services/rehab/rehab-institute/conditions/spinal-cord-injury/follow-up-medical-care",
  },
  {
    id: 21,
    HospitalName: "Baylor Scott & White Institute for Rehabilitation",
    Speciality: "Inpatient",
    Street: "909 N Washington Ave",
    City: "Dallas, TX",
    Zip: 75246,
    Lat: 32.7962,
    Lng: -96.7695,
    Phone: "214-820-9300",
    Website:
      "https://www.bswrehab.com/about-us/featured-programs/spinal-cord-injury-rehabilitation/",
  },
  {
    id: 22,
    HospitalName: "TIRR Memorial Hermann",
    Speciality: "Inpatient & Outpatient",
    Street: "1333 Moursund",
    City: "Houston, TX",
    Zip: 77030,
    Lat: 29.70836,
    Lng: -95.40193,
    Phone: "713-797-5942",
    Website:
      "https://memorialhermann.org/services/treatments/spinal-cord-injury-rehabilitation",
  },
  {

    id: 23,
    HospitalName: "Sheltering Arms Institute",
    Speciality: "Inpatient",
    Street: "2000 Wilkes Ridge Drive",
    City: "Richmond, VA",
    Zip: 23233,
    Lat: 37.541290,
    Lng: -77.434769,
    Phone: "804-877-4000",
    Website:
      "https://shelteringarmsinstitute.com/conditions-services/spinal-cord-injury-therapy/",
  },
  {
    id: 31,
    DoctorName: "Tanya A. Wahl, MD",
    HospitalName: "Swedish Cancer Institute - Issaquah",
    Speciality: "Medical Oncology, Hematology Oncology, Hematology",
    Street: "751 NE Blakely Dr\nSuite 1090",
    Lat: 47.5357121,
    Lng: -122.0217681,
    City: "Issaquah, WA",
    Zip: "98029",
    Phone: "(425) 313-4200",
    Website: "https:\/\/www.swedish.org\/locations\/swedish-cancer-institute-issaquah"
  },
  {
    id: 29,
    DoctorName: "John E. Benson, MD",
    HospitalName: "Swedish Cherry Hill - Jefferson Tower",
    Speciality: "Physiatrist",
    Street: "1600 East Jefferson Street, Suite 300",
    Lat: 47.60649,
    Lng: -122.3114,
    City: "Seattle, WA",
    Zip: "98122",
    Phone: "(206) 320-2600",
    Website: "https:\/\/www.swedish.org\/locations\/swedish-spine-sports-medicine-cherry-hill"
  },
  {
    id: 30,
    DoctorName: "Kimberly W. Middleton, MD",
    HospitalName: "Swedish Cherry Hill - Jefferson Tower",
    Speciality: "Physiatrist",
    Street: "1600 East Jefferson Street, Suite 300",
    Lat: 47.6065478,
    Lng: -122.311001,
    City: "Seattle, WA",
    Zip: "98122",
    Phone: "(206) 320-2600",
    Website: "https:\/\/www.swedish.org\/locations\/swedish-spine-sports-medicine-cherry-hill"
  },
  {
    id: 32,
    DoctorName: "Charlotte H. Smith, MD",
    HospitalName: "PeaceHealth United General Medical Center",
    Speciality: "Physical Medicine & Rehabilitation",
    Street: "2000 Hospital Drive",
    Lat: 32.8204702,
    Lng: -79.8504422,
    City: "Sedro Woolley, WA",
    Zip: "98284",
    Phone: "360-856-7438",
    Website: "https:\/\/www.peacehealth.org\/united-general-medical-center"
  },
];
