import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {
  faPhone,
  faMapPin,
  faUserMd
} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-contact-card',
  templateUrl: './contact-card.component.html',
  styleUrls: ['./contact-card.component.scss']
})
export class ContactCardComponent implements OnInit {
  @Input() id: number;
  @Input() email: string;
  @Input() address: string;
  @Input() name: string;
  @Input() fax: string;
  @Input() phone: string;

  @Input() doctorName: string;
  @Input() Speciality: string;
  @Input() hospitalName: string;
  @Input() city: string;
  @Input() zip: string;
  @Input() website: string;

  @Input() type: string;
  @Input() isActive: boolean;

  @Output() clickCard: EventEmitter<any> = new EventEmitter<any>();

  iconPhone = faPhone;
  iconAddress = faMapPin;
  iconDoctor = faUserMd;

  constructor() { }

  ngOnInit(): void { }

  onClickCard($event) {
    $event.preventDefault();
    $event.stopPropagation();
    this.clickCard.emit({ hospital: this.hospitalName, id: this.id });
  }

  openWebsite($event, website){
    $event.preventDefault();
    window.open(website, "_target");
  }
}
