<mat-card class="wrapper">
    <div class="container">
        <h2 class="about-title">Spinal Cord Injury and Disability Research Center (SCIDR) at TIRR Memorial Hermann</h2>
        <img class="divider" src="../../../assets/img/divider.png" />
        <div class="subBlock">
            <p>
                The mission of the SCIDR is to improve functional recovery, health and quality of life for individuals
                with
                spinal cord injury (SCI) and other physical disabilities. SCIDR had been conducting research on SCI
                long
                before 1972 when it became among the first inpatient rehabilitation programs to be designated as a
                Model
                System of Care by the National Institute for Disability and Rehabilitation Research (NIDRR).
            </p>
            <p>
                SCIDR conducts investigator-initiated and industry-sponsored studies, including local studies,
                multicenter
                studies and clinical trials of individuals of all ages with SCI and physical disability. Research
                focuses on
                optimizing acute and chronic care for people with spinal cord injury, facilitating active community
                involvement and access to care, improving quality of life and reducing the risk of secondary
                complications
                and conditions such as depression and chronic pain. Investigators at SCIDR have extensive experience
                developing and testing interventions to improve functioning, psychosocial health and quality of life
                across
                the lifespan.
            </p>
        </div>
        <div class="subBlock">
            <h4><strong>Research Projects & Resources</strong></h4>
            <p>
                Our ongoing and previous research projects can be found on our upcoming SCIDR website.
            </p>
        </div>

        <div class="subBlock">
            <h4><strong>Meet the SCIDR Team</strong></h4>

            <mat-card class="info-card-wrapper">
                <p>
                    <strong>Heather Taylor, Ph.D.</strong>
                    is the Director for Spinal Cord Injury and Disability Research (SCIDR) at TIRR Memorial Hermann
                    and
                    Associate
                    Professor for Physical Medicine and Rehabilitation at UTHealth. She also holds an adjunct
                    faculty
                    appointment
                    at Baylor College of Medicine. She is the former Chair for both the Spinal Cord Injury Model
                    Systems and
                    the
                    American Congress for Rehabilitation Medicine (ACRM) Special Interest Group (SIG) Women’s Health
                    Task
                    Force
                    focusing on the unique concerns of women’s health after spinal cord injury (SCI). Dr. Taylor has
                    focused
                    her
                    research on health, motor recovery and learning, and psychosocial functioning with a strong
                    emphasis on
                    women
                    and children with SCI. Dr. Taylor has a long history conducting research with a mission to
                    improve the
                    lives
                    of people with disabilities and has been Principal Investigator/Co-Investigator on over 30
                    funded
                    projects.
                </p>
            </mat-card>

            <mat-card class="info-card-wrapper">
                <p>
                    <strong>Susan Robinson-Whelen, Ph.D.</strong>
                    is a Scientist at TIRR Memorial Hermann’s Spinal Cord Injury and Disability Research Center (SCIDR), Senior Investigator at Baylor College of Medicine’s (BCM) Center for Research on Women with Disabilities (CROWD), and Assistant Professor at BCM’s H. Ben Taub Department of Physical Medicine and Rehabilitation. For the past 25 years, she has conducted research on the health and well-being of people with disabilities, primarily women with mobility impairments, including spinal cord injury.
                </p>
            </mat-card>

            <mat-card class="info-card-wrapper">
                <p>
                    <strong>Jessica Rodriguez, B.A.</strong>
                    is the Research Operation Coordinator for the Spinal Cord Injury and Disability Research Center at TIRR Memorial Hermann. She has over 2 years experience contributing to research on traumatic brain injury for the TBI Model Systems and over 2 years experience coordinating a federally funded project evaluating loneliness among men and women with SCI. Additionally, she works on a project that aims to understand patterns of symptoms after acquired injuries to the brain, spine, or limb.  Her experience includes conducting interviews and neuropsychological evaluations across disability groups. Ms. Rodriguez has exceptional experience in database development including being proficient in RedCap. She has used her RedCap experience to develop screenings for participants, consents, and systems to track participants throughout the project.
                </p>
            </mat-card>

            <mat-card class="info-card-wrapper">
                <p>
                    <strong>Sarah Hoffman, B.A.</strong>
                    is a Research Assistant in the Spinal Cord Injury and Disability Research Center at TIRR Memorial Hermann. During her 3 years at TIRR, she has worked on multiple research projects focusing on aspects of health and wellness after Spinal Cord Injury (SCI). She coordinated the Craig Neilsen Creating Opportunities and Independence (CO&I) project, HeRe for Women with SCI, and was instrumental in all aspects of the development and implementation of the project including recruiting and organizing focus groups and developing the website and Women’s Health after SCI Network. Her background in public relations and graphic design has been essential in producing recruitment and research dissemination materials for many projects. In addition, Ms. Hoffman is coordinating the longitudinal study for the SCI Model Systems in the current cycle.
                </p>
            </mat-card>

            <mat-card class="info-card-wrapper">
                <p>
                    <strong>Diana Gonzalez, B.A.</strong>
                    is a Research Assistant in the Spinal Cord Injury and Disability Research Center at TIRR Memorial Hermann. For the past three years, Ms. Gonzalez has contributed to research on a dynamic psychosocial health intervention for women with SCI. She has aided in data management, study analyses, findings, scientific conference presentations and papers. She has been instrumental in all aspects of research coordination including intervention sessions across challenging topics including depression and sexuality. Ms. Gonzalez is very proactive and has worked with investigators on the development of multiple video products in the online platform of Second Life. She has also taken the lead in coordinating a multi-site project on sexual wellness after SCI among men and women. Most recently, Ms. Gonzalez is assisting in the development of a health promotion group intervention for people aging with SCI.
                </p>
            </mat-card>

            <mat-card class="info-card-wrapper">
                <p>
                    <strong>Garett Parrish, M.S.</strong>
                    is a Research Assistant in the Spinal Cord Injury and Disability Research Center at TIRR Memorial Hermann. He is working on the Living Longer and Stronger project and the longitudinal study for the Spinal Cord Injury (SCI) Model Systems. Mr. Parrish suffered a spinal cord injury in 2015 and has made it his goal to help others affected by paralysis. Mr. Parrish earned his master's degree from Angelo State University (ASU) in experimental psychology with an emphasis in behavioral neuroscience. He conducted his thesis on college students' perceptions of sexuality in people with physical disabilities. Garett has experience in SPSS, Qualtrics, PsychData, and Jamovi. Mr. Parrish conducted animal model studies at ASU, examining empathy and prosocial behavior in mice, the findings of which were presented at the 2021 Annual Conference of the Society for Neuroscience.
                </p>
            </mat-card>
        </div>

    </div>
</mat-card>