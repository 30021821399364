import {
  Component,
  OnInit,
  ViewChild,
  ChangeDetectorRef,
  OnDestroy,
  ElementRef,
  Renderer2,
  AfterViewChecked
} from '@angular/core';
import { resources } from 'mock-data';
import { ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Card } from 'src/app/components/long-card/long-card.component';
import { Observable, Subscription } from 'rxjs';
import { FormGroup, FormControl } from '@angular/forms';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { ResoucesService } from 'src/app/services/resouces.service';
import { take } from 'rxjs/operators';
import { MatAccordion, MatExpansionPanel } from '@angular/material/expansion';

export interface Tag {
  name: string;
  completed: boolean;
  color: string;
  subtags?: Tag[];
}

export const setMediums = {
  "app": true,
  "checklist": true,
  "download": true,
  "factsheet": true,
  "forum": true,
  "handbook": true,
  "PDF": true,
  "research": true,
  "series": true,
  "social media": true,
  "video": true,
  "website": true
}

@Component({
  selector: 'app-resources',
  templateUrl: './resources.component.html',
  styleUrls: ['./resources.component.scss']
})

export class ResourcesComponent implements OnInit, OnDestroy, AfterViewChecked {
  SortField: FormGroup;
  iconCaretDown = faCaretDown;
  iconCaretUp = faCaretUp;
  isShowDropdown: boolean = false;

  isPanelOpen: boolean = false;
  isPanelTags: boolean = false;

  page = 1;
  pageSize = 5;

  headerImgMap = {
    'Access': 'ACCESS.jpg',
    'Advocacy': 'ADVOCACY.jpg',
    'Bladder & Bowel': 'BLADDERBOWEL.jpg',
    'Gynecology': 'OB_GYN.jpg',
    'Mental Health': 'MENTALHEALTH.jpg',
  }

  tabIndex = {
    'Access': 0,
    'Advocacy': 1,
    'BladderBowel': 2,
    'Gynecology': 3,
    'MentalHealth': 4,
  }

  routeFragment: string = "Access";

  selected: any;

  resources = resources;
  selectedResource: any = {};
  filteredArticles: any = [];
  sortOPtions: string[] = ['A-Z', 'Z-A', 'Most Liked', 'Most Recent', 'Oldest'];
  items = Array.from(this.sortOPtions).map((_, i) => ({
    id: i,
    label: _
  }));
  dataSource: any;

  filteredOptions: any = new Observable();
  resourceAlias = {
    MentalHealth: 'MH',
    Advocacy: 'AVC',
    ['Gynecology']: 'GNG',
    ['BladderBowel']: 'BAB',
    Access: "ACS"
  }
  selectedResourceAlias: string;
  activeResource: any[];
  isLoading: boolean = true;
  filterOptions = ['Access', 'Advocacy', 'Bladder & Bowel', 'Gynecology', 'Mental Health'];

  tag: Tag = {
    name: 'Select All',
    completed: true,
    color: 'primary',
    subtags: []
  }

  medium: Tag = {
    name: 'Select All',
    completed: true,
    color: 'primary',
    subtags: []
  }

  allCompleteTags: boolean = true;
  allCompleteMediums: boolean = true;

  @ViewChild('description', { static: false }) description: ElementRef;
  @ViewChild('sticky', { static: false }) sticky: ElementRef<HTMLInputElement>;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  obs: Observable<any>;

  @ViewChild("pannelTags", { static: false }) pannelTags: MatExpansionPanel;
  @ViewChild("pannelMediums", { static: false }) pannelMediums?: MatExpansionPanel;
  @ViewChild("accordionElem") accordion: MatAccordion;

  constructor(
    private activatedRoute: ActivatedRoute,
    private changeDetectorRef: ChangeDetectorRef,
    private resourcesService: ResoucesService,
    private renderer: Renderer2
  ) {
    this.SortField = new FormGroup({
      sort: new FormControl('Most Recent')
    });

    this.selected = new FormControl(this.tabIndex["Access"]);
    this.activeResource = resources["Access"];
    this.selectedResourceAlias = this.resourceAlias['Access'];
    //this.getTopicData('Access');
  }

  async ngOnInit() {
    this.activatedRoute.paramMap.subscribe(params => {
      if (this.tabIndex.hasOwnProperty(params.get('resourceType'))) {
        this.routeFragment = params.get('resourceType');
        this.selected = new FormControl(this.tabIndex[this.routeFragment]);
        this.activeResource = resources[this.routeFragment];
        this.selectedResourceAlias = this.resourceAlias[this.routeFragment];

        this.getTopicData(this.routeFragment);
        // this.selectedResource.articles.paginator = this.paginator;

        this.dataSource = new MatTableDataSource<Card>(this.filteredArticles);

        this.changeDetectorRef.detectChanges();
        this.dataSource.paginator = this.paginator;
        this.obs = this.dataSource.connect();
      }
      // this.setPaginator();
    });

    this.changeSortCriteria({ id: 3, label: 'Most Recent' });
  }

  ngAfterViewChecked() {
    // if (this.description) {
    //   const num = 49 + 30 + 61 + document.querySelector(".description").clientHeight;
    //   this.renderer.setStyle(this.sticky.nativeElement, "margin-top", `${num}px`);
    // }
  }

  getTopicData(routeFragment): Subscription {
    this.isLoading = true;
    return this.resourcesService.getTopic(this.selectedResourceAlias).pipe(take(1)).subscribe(resource => {
      this.selectedResource = resource;
      this.selected.setValue(this.tabIndex[routeFragment])
      const introducedTags = {};
      this.tag.subtags = [];
      this.medium.subtags = [];
      this.selectedResource.articles.forEach(article => {
        if (article.Tags?.length)
          article.Tags?.forEach(({ tagName }) => {
            if (!introducedTags.hasOwnProperty(tagName)) {
              let subtag: Tag = {
                name: tagName,
                completed: true,
                color: 'primary'
              }

              if (setMediums[tagName]) {
                this.medium.subtags.push(subtag);
              }
              else this.tag.subtags.push(subtag);
            }

            introducedTags[tagName] = true;
          });
      });

      this.tag.subtags.sort((a, b) => a.name.localeCompare(b.name));
      this.medium.subtags.sort((a, b) => a.name.localeCompare(b.name));
      this.filteredArticles = this.selectedResource.articles;
      this.changeSortCriteria({ id: this.items.find(item => item.label === this.SortField.controls.sort.value).id, label: this.SortField.controls.sort.value });
      this.isLoading = false;
    });
  }

  // onFilterArticles(tagSelected) {
  //   this.setPaginator();
  //   this.changeSortCriteria({ id: 3, label: 'Most Recent' });
  // }

  handleOnFilter(filterList, allCompleteType) {
    console.log("hadle")
    if (allCompleteType === 'tag')
      this.allCompleteTags = filterList.subtags.every(t => t.completed);
    else if (allCompleteType === 'medium')
      this.allCompleteMediums = filterList.subtags.every(t => t.completed);

    this.filteredArticles = this.selectedResource.articles.filter(article =>
      article.Tags?.some(tag =>
        filterList.subtags.some(subtag =>
          subtag.name === tag.tagName && subtag.completed
        )
      )
    )
  }

  someComplete(filterList): boolean {
    return filterList.subtags.some(t => t.completed);
  }

  selectDeselectAll(selectAll: boolean, filterList, filterType) {
    //console.log(completed)
    if (filterType === 'tag')
      this.allCompleteTags = selectAll;
    else if (filterType === 'medium')
      this.allCompleteMediums = selectAll;

    filterList.subtags.forEach(t => (t.completed = selectAll));

    if (!selectAll) {
      this.filteredArticles = this.selectedResource.articles.filter(article => article.Tags?.some(tag =>
        filterList.subtags.some(subtag =>
          (subtag.name === tag.tagName && subtag.completed)
        )
      ));
    } else {
      this.filteredArticles = this.selectedResource.articles.filter(article => article.Tags?.some(tag =>
        filterList.subtags.some(subtag =>
          !(subtag.name === tag.tagName && subtag.completed)
        )
      ));
    }
  }

  changeSortCriteria($event: any) {
    this.SortField.controls.sort.setValue($event.label);
    switch ($event.id) {
      case 0:
        this.filteredArticles.sort((a, b) => a.articleName.localeCompare(b.articleName))
        this.setPaginator();
        break;
      case 1:
        this.filteredArticles.sort((a, b) => b.articleName.localeCompare(a.articleName));
        this.setPaginator();
        break;
      case 2:
        this.filteredArticles.sort((a, b) => b.ratingAvg - a.ratingAvg);
        this.setPaginator();
        break;
      case 3:
        this.filteredArticles = _.orderBy(
          this.filteredArticles,
          ['createdAt'],
          ['desc']
        );
        this.setPaginator();
        break;
      case 4:
        this.filteredArticles = _.orderBy(
          this.filteredArticles,
          ['createdAt'],
          ['asc']
        );
        this.setPaginator();
        break;
    }
  }

  setPaginator() {
    this.dataSource = new MatTableDataSource<Card>(this.filteredArticles);
    this.dataSource.paginator = this.paginator;
    this.obs = this.dataSource.connect();
    if (this.dataSource) {
      this.dataSource.disconnect();
    }
  }

  transformString(menuItem) {
    return menuItem.replace(/\s|\/|&|/g, '');
  }

  handleTagClick() {
    this.pannelTags.open();
    this.pannelMediums.open();

    this.pannelTags._body.nativeElement.focus();
    this.accordion['nativeElement'].focus({ focusVisible: 'true' });
  }

  ngOnDestroy() {
    if (this.dataSource) {
      this.dataSource.disconnect();
    }
  }
}
