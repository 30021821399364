import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { Observable } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { Card } from '../long-card/long-card.component';
import * as _ from 'lodash';
import { ArticlesResultService } from './articles-result.service';
import { FormGroup, FormControl } from '@angular/forms';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { ResoucesService } from 'src/app/services/resouces.service';
import { setMediums, Tag } from 'src/app/pages/resources/resources.component';
import { MatAccordion, MatExpansionPanel } from '@angular/material/expansion';

@Component({
  selector: 'app-articles-result',
  templateUrl: './articles-result.component.html',
  styleUrls: ['./articles-result.component.scss']
})
export class ArticlesResultComponent implements OnInit {
  SortField: FormGroup;
  iconCaretDown = faCaretDown;
  iconCaretUp = faCaretUp;
  isShowDropdown: boolean = false;

  isPanelOpen: boolean = false;
  isPanelTags:boolean = false;

  page = 1;
  pageSize = 5;

  searchText: string = null;
  selectedTags: string[] = [];
  filteredArticles: any[];
  articlesOriginal: any[];
  tags = {};
  sortOPtions: string[] = ['A-Z', 'Z-A', 'Most Liked', 'Most Recent', 'Oldest'];
  items = Array.from(this.sortOPtions).map((_, i) => ({
    id: i,
    label: _
  }));
  dataSource: any;

  isLoading: boolean = false;

  tag: Tag = {
    name: 'Select All',
    completed: true,
    color: 'primary',
    subtags: []
  }

  medium: Tag = {
    name: 'Select All',
    completed: true,
    color: 'primary',
    subtags: []
  }

  allCompleteTags: boolean = true;
  allCompleteMediums: boolean = true;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  obs: Observable<any>;

  @ViewChild("pannelTags", {static: false}) pannelTags: MatExpansionPanel;
  @ViewChild("pannelMediums", {static: false}) pannelMediums?: MatExpansionPanel;
  @ViewChild("accordionElem") accordion: MatAccordion;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private articleResultService: ArticlesResultService,
    private resourceService: ResoucesService,
    private articleResultsService: ArticlesResultService
  ) {
    this.SortField = new FormGroup({
      sort: new FormControl('Most Recent')
    });

    this.articleResultService.searchText.subscribe(
      searchText => (this.searchText = searchText || 'All Resources')
    );

    let introducedTags = {};

    this.articleResultService.articles.subscribe(articles => {
      this.articlesOriginal = articles;
      this.filteredArticles = this.articlesOriginal;
      this.handleSortBy(this.items.find(item=> this.SortField.controls.sort.value===item.label).id);
      this.dataSource = new MatTableDataSource<Card>(this.filteredArticles);
      // this.selectedArticles.paginator = this.paginator;
      this.medium.subtags = [];
      this.tag.subtags = [];
      introducedTags = {};
      this.articlesOriginal.forEach(article => {
        if (article.Tags?.length) {
          article.Tags?.forEach(({ tagName }) => {
            if (introducedTags[tagName] === undefined) {
              let subtag: Tag = {
                name: tagName,
                completed: true,
                color: 'primary'
              }

              if (setMediums[tagName]) {
                this.medium.subtags.push(subtag);
              }
              else this.tag.subtags.push(subtag);
            }
            introducedTags[tagName] = true;
          })
        }
      });

      this.tag.subtags.sort((a,b)=>  a.name.localeCompare(b.name));
      this.medium.subtags.sort((a,b)=>  a.name.localeCompare(b.name));
    });
  }

  ngOnInit(): void {
    this.changeDetectorRef.detectChanges();
    this.resourceService.isLoadingSearch.subscribe(isLoading => {
      this.isLoading = isLoading;
    });
  }

  handleOnFilter(filterList, allCompleteType) {
    if (allCompleteType === 'tag')
      this.allCompleteTags = filterList.subtags.every(t => t.completed);
    else if (allCompleteType === 'medium')
      this.allCompleteMediums = filterList.subtags.every(t => t.completed);

    this.filteredArticles = this.articlesOriginal.filter(article =>
      article.Tags?.some(tag =>
        filterList.subtags.some(subtag =>
          subtag.name === tag.tagName && subtag.completed
        )
      )
    )
  }

  someComplete(filterList): boolean {
    return filterList.subtags.some(t => t.completed);
  };

  selectDeselectAll(selectAll: boolean, filterList, filterType) {
    //console.log(completed)
    if (filterType === 'tag')
      this.allCompleteTags = selectAll;
    else if (filterType === 'medium')
      this.allCompleteMediums = selectAll;

    filterList.subtags.forEach(t => (t.completed = selectAll));

    if (!selectAll) {
      this.filteredArticles = this.articlesOriginal.filter(article => article.Tags?.some(tag =>
        filterList.subtags.some(subtag =>
          (subtag.name === tag.tagName && subtag.completed)
        )
      ));
    } else {
      this.filteredArticles = this.articlesOriginal.filter(article => article.Tags?.some(tag =>
        filterList.subtags.some(subtag =>
          !(subtag.name === tag.tagName && subtag.completed)
        )
      ));
    }
  }

  changeSortCriteria($event: any) {
    this.SortField.controls.sort.setValue($event.label);
    this.handleSortBy($event.id)
  }

  handleSortBy(sortValue: number) {
    switch (sortValue) {
      case 0:
        this.filteredArticles.sort((a, b) => a.articleName.localeCompare(b.articleName))
        this.setPaginator();
        break;
      case 1:
        this.filteredArticles.sort((a, b) => b.articleName.localeCompare(a.articleName));
        this.setPaginator();
        break;
      case 2:
        this.filteredArticles.sort((a, b) => b.ratingAvg - a.ratingAvg);
        this.setPaginator();
        break;
      case 3:
        this.filteredArticles = _.orderBy(
          this.filteredArticles,
          ['createdAt'],
          ['desc']
        );
        this.setPaginator();
        break;
      case 4:
        this.filteredArticles = _.orderBy(
          this.filteredArticles,
          ['createdAt'],
          ['asc']
        );
        this.setPaginator();
        break;
    }
  }

  setPaginator() {
    this.dataSource = new MatTableDataSource<Card>(this.filteredArticles);
    this.dataSource.paginator = this.paginator;
    this.obs = this.dataSource.connect();
    if (this.dataSource) {
      this.dataSource.disconnect();
    }
  }

  handleTagClick() {
    this.pannelTags.open();
    //this.pannelMediums.open();

    this.pannelTags._body.nativeElement.focus();
    this.accordion['nativeElement'].focus({focusVisible: 'true'});
  }

  ngOnDestroy() {
    if (this.dataSource) {
      this.dataSource.disconnect();
    }
  }
}
