import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MapService {

  constructor(private http: HttpClient) { }

  getZipCodes(zipCode): Observable<any> {
    const headers = new HttpHeaders()
      .set('content-type', 'application/json')
      .set('Access-Control-Allow-Origin', '*');
    return this.http.get<any>(`https://www.zipcodeapi.com/rest/js-0FD0BmZqlo3bEqGbHNEN9QsdMGoid62rxvbKEzPWbcleHgAfL4ELZKKVxIjyHzBP/radius.json/${zipCode}/15/miles?minimal`).pipe(map(zipCodes => {
      return zipCodes;
    }))
  }

}
