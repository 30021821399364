import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ArticlesResultService {
  articles = new BehaviorSubject<any[]>([]);
  searchText = new BehaviorSubject<string>(null);
  constructor() {}
}
