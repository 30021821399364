import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-physicians-about',
  templateUrl: './physicians-about.component.html',
  styleUrls: ['./physicians-about.component.scss']
})
export class PhysiciansAboutComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
