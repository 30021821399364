import { AfterViewInit, Component, ElementRef, HostListener, OnInit, Renderer2 } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit {

  private domElement: HTMLElement;
  constructor(private renderer: Renderer2, private elementRef: ElementRef) {
    this.domElement = this.elementRef.nativeElement as HTMLElement;
    //this.domElement = document.getElementsByClassName('wrapper-center')

  }
  @HostListener('window:resize', ['$event'])
  onResize() {
    //event.target.innerWidth;
    // console.log(document.getElementsByTagName('footer')[0].clientHeight);
    // const footerHeight = document.getElementsByTagName('footer')[0].clientHeight;

    // const mainContElem = document.getElementsByClassName('mainContainer')[0].clientHeight;
    // if(document.getElementsByClassName('mainContainer')[0].clientHeight <= 940){
    //   this.renderer.setStyle(document.getElementsByClassName('wrapper-center')[0], "height", `auto`);
    //   return;
    // }
    // console.log(mainContElem, footerHeight)
    // this.renderer.setStyle(document.getElementsByClassName('wrapper-center')[0], "height", `${mainContElem - footerHeight}px`);

  }
  ngOnInit(): void {
    //event.target.innerWidth;
    // const footerHeight = document.getElementsByTagName('footer')[0].clientHeight;
    // const mainContElem = document.body.scrollHeight;
    // console.log(mainContElem, footerHeight)
    // this.renderer.setStyle(document.getElementsByClassName('wrapper-center')[0], "height", `${mainContElem - footerHeight}px`);

    // const wrapElem = document.getElementsByClassName('wrapper-center');

    // wrapElem.addEventListener()

  }

  ngAfterViewInit(): void {
    // const footerHeight = document.getElementsByTagName('footer')[0].clientHeight;
    // const mainContElem = document.body.scrollHeight;
    // console.log(mainContElem, footerHeight)
    // this.renderer.setStyle(document.getElementsByClassName('wrapper-center')[0], "height", `${mainContElem - footerHeight}px`);

  }

}
