import { Component, Input, OnInit } from '@angular/core';
import { newsletters } from 'mock-data-newsletter';
import { ActivatedRoute } from '@angular/router';
import {
  faChevronCircleRight,
  faChevronCircleLeft
} from '@fortawesome/free-solid-svg-icons';
import { ViewportScroller } from '@angular/common';

interface Newsletter {
  title: string;
  text: string;
  page: number;
  date: Date;
}

@Component({
  selector: 'app-network',
  templateUrl: './network.component.html',
  styleUrls: ['./network.component.scss']
})
export class NetworkComponent {
  selectedNewsletter: Newsletter = null;
  newsletters = newsletters;
  faArrowRight = faChevronCircleRight;
  faArrowLeft = faChevronCircleLeft;

  pageType: string = "";

  constructor(private activatedRoute: ActivatedRoute, private scroller: ViewportScroller) {
    this.activatedRoute.paramMap.subscribe(params => {
      this.pageType = params.get('networkType');
    });
    this.selectedNewsletter = newsletters[newsletters.length - 1];
  }

  scrollup($e){
    $e.preventDefault();
    //$e.stopPropagation();
    //debugger
    this.scroller.scrollToAnchor("tt");
  }
}
