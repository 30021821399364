<mat-card class="card-ru bg-image hover-overlay hover-zoom hover-shadow ripple">
  <div *ngIf="isImgTop && img">
    <fa-icon class="fa-3x" [icon]="img"></fa-icon>
  </div>
  <mat-card-header>
    <mat-card-title class="title">{{ isImgTop ? title : title.toUpperCase() }}</mat-card-title>
  </mat-card-header>
  <img *ngIf="!isImgTop" mat-card-image src="{{'../../../assets/img/' + img }}" />
  <div class="mask" style="
      background: linear-gradient(
        45deg,
        rgba(29, 236, 197, 0.3),
        rgba(91, 14, 214, 0.3) 100%
      );">
  </div>
</mat-card>