<mat-card class="wrapper" id="AboutSite">
  <div class="container">
    <h2 class="about-title">About the Site</h2>
    <img class="divider" src="../../../assets/img/divider.png" />
    <p>
      Our mission is to improve access to healthcare services, information, and education to bridge existing information
      gaps for women with SCI. The purpose of this website is to provide a directory of links to
      consumer-based information, education, and resources on healthcare topics for women after SCI (e.g., videos,
      downloadable books). This site also provides a growing list of practitioners who serve women with SCI
      throughout the nation and a network of professionals and consumers who are interested in the health and
      wellness of women with SCI.
    </p>
    <p>
      The effort for this infrastructure was funded by the Craig H. Neilsen Foundation (Grant Number: 590114). To
      inform this work, we conducted semi-structured focus groups with women with spinal cord injury (SCI) and
      healthcare providers to identify the top healthcare needs/service priorities for women living with SCI.
      Additionally, this effort included a Community Advisory Board that supervised the project every step of the
      way from the creation of the focus group questions to the design and resources on this website.
    </p>
    <p>
      Our focus group findings highlighted the need for accessible, consumer-friendly healthcare information, and
      this site is intended to be a growing and developing directory for women with SCI, their caregivers, and
      healthcare professionals. Women with SCI identified a broad range of topics needing attention with access to
      healthcare and information overarching and connecting all topics. As proposed, only five topics could be
      selected to launch this website due to funding and time constraints. The focus groups identified the top topics
      to include Access, Advocacy, Bladder & Bowel, Mental Health and Gynecologic Health.

    </p>
    <p>
      The website is designed with links to resources on each of these topics. This list is intended to grow and update
      as new resources become available. If you have any resources you would like to share, you can fill out the form
      <a href="#" [routerLink]="'/Network/Share'">here</a> for our team to review.
    </p>
    <p>
      Please note, this site is not intended to include professional publications that can be found in peer-reviewed
      journals or professional medical information (<a href="#" (click)="openModal($event, content)">disclaimer info here</a>).
      However, the resources included in this
      directory may provide links to sites that include this information. Please always talk to your healthcare
      provider when considering any health care resource.
    </p>
  </div>
</mat-card>

<ng-template #content let-modal>
  <div class="modal-container">
      <h5 [innerHTML]="modalContent.title"></h5>
      <div class="body-text" [innerHTML]="modalContent.body">
      </div>
  </div>
</ng-template>