import { Component, OnInit } from '@angular/core';
import { newsletters } from 'mock-data-newsletter';
import { ActivatedRoute } from '@angular/router';
import {
  faChevronCircleRight,
  faChevronCircleLeft
} from '@fortawesome/free-solid-svg-icons';

interface Newsletter {
  title: string;
  text: string;
  page: number;
  date: number;
}

@Component({
  selector: 'app-newsletter',
  templateUrl: './newsletter.component.html',
  styleUrls: ['./newsletter.component.scss']
})
export class NewsletterComponent implements OnInit {
  //panelOpenState:boolean = false;

  isJoinUsView: boolean = false;
  selectedNewsletter: Newsletter = null;
  newsletters = newsletters;
  faArrowRight = faChevronCircleRight;
  faArrowLeft = faChevronCircleLeft;

  constructor(private activatedRoute: ActivatedRoute) {
    this.activatedRoute.paramMap.subscribe(params => {
      const networkView = params.get('networkType');
      this.isJoinUsView = networkView === 'Neswletter';
    });
    this.selectedNewsletter = newsletters[newsletters.length - 1];
  }

  ngOnInit(): void {
  }

  onPreviousNewsletter() {
    const selectedPage = this.selectedNewsletter.page - 1;
    this.selectedNewsletter = newsletters.filter(
      newsletter => newsletter.page === selectedPage
    )[0];
  }

  onNextNewsletter() {
    const selectedPage = this.selectedNewsletter.page + 1;
    this.selectedNewsletter = newsletters.filter(
      newsletter => newsletter.page === selectedPage
    )[0];
  }

  setLeftArrowTooltip() {
    if (this.selectedNewsletter.page - 2 >= 0) {
      return `Go to ${newsletters[
        this.selectedNewsletter.page - 2
      ].title.toUpperCase()} newsletter`;
    }
  }

  setRightArrowTooltip() {
    if (this.selectedNewsletter.page < newsletters.length) {
      return `Go to ${newsletters[
        this.selectedNewsletter.page
      ].title.toUpperCase()} newsletter`;
    }
  }
}
