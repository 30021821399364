import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { disclaimers } from 'src/app/components/footer/footer.component';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {
  aboutType: string;

  description: string;
  team: string;

  modalRef: any;
  modalContent: any;

  constructor(private activatedRoute: ActivatedRoute, private modalService: NgbModal) { }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe(params => {
      this.aboutType = params.get('aboutType');
      if (this.aboutType === 'HeRe') {
        this.team = this.aboutType;
        this.description =
          'HeRe Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod temporincididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quisnostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum';
      } else {
        this.team = 'SCIDR';
        this.description =
          'SCIDR Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod temporincididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quisnostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum';
      }
    });
  }

  openModal(e, content): void {
    e.stopPropagation();
    e.preventDefault();

    this.modalRef = this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', centered: true, size: "lg" });
    this.modalContent = disclaimers["websiteDisclaimer"];
  }

  closeModal() {
    this.modalRef.close();
  }
}
