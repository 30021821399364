<mat-card class="form-container">
  <h2>Share Resources</h2>
  <img class="divider" src="../../../assets/img/divider.png" />
  <p class="form-desc small">
    The purpose of this website is to provide a directory of links to consumer-based information, education, and
    resources on healthcare topics for women after SCI. Please share any resources you or someone you know has found
    helpful, and they may be added to the site if they meet the criteria.</p>
  <form [formGroup]="ShareResourcesForm" (ngSubmit)="onSubmit()">
    <mat-form-field appearance="outline">
      <mat-label>Name - <em>Optional</em></mat-label>
      <input matInput [formControl]="ShareResourcesForm.controls.name" placeholder="Example: Dana">
      <mat-icon matSuffix>person</mat-icon>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Last Name - <em>Optional</em></mat-label>
      <input matInput [formControl]="ShareResourcesForm.controls.lastName" placeholder="Example: Foster">
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Email - <em>Optional</em></mat-label>
      <input matInput [formControl]="ShareResourcesForm.controls.email" placeholder="Example: dana_foster@gmail.com">
      <mat-icon matSuffix>email</mat-icon>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Resource Name - <em>Optional</em></mat-label>
      <input matInput [formControl]="ShareResourcesForm.controls.resourceName"
        placeholder="Example: How to cope with pain">
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Resource Type - <em>Optional</em></mat-label>
      <mat-select [formControl]="ShareResourcesForm.controls.resourceType">
        <mat-option *ngFor="let option of dropdownOptions" [value]="option">
          {{option}}
        </mat-option>
      </mat-select>
    </mat-form-field>


    <div class="form-group input-file" *ngIf="ShareResourcesForm.controls.resourceType.value === 'Attachment'">
      <label for="resourceAttachment">Add an Attachment - <em>Optional</em></label>
      <input type="file" class="form-control" id="resourceAttachment" formControlName="resourceAttachment"
      (change)="onFileChange($event)" />
    </div>

    <mat-form-field appearance="outline" *ngIf="ShareResourcesForm.controls.resourceType.value === 'Link'">
      <mat-label>Share a Link - <em>Optional</em></mat-label>
      <input matInput type="url" [formControl]="ShareResourcesForm.controls.resourceLink"
        placeholder="https://SCIcopewithpain.com">
    </mat-form-field>

    <mat-form-field appearance="outline" *ngIf="ShareResourcesForm.controls.resourceType.value === 'Text'">
      <mat-label>Add a Text - <i>Optional</i>
      </mat-label>
      <textarea matInput type="text" class="form-control" id="resourceText"
        [formControl]="ShareResourcesForm.controls.resourceText" maxlength="500"></textarea>
    </mat-form-field>

    <button class="btn btn-primary" type="submit" [disabled]="!ShareResourcesForm.valid">
      Submit
    </button>
  </form>
</mat-card>