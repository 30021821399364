import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  faUserCircle,
  faEnvelope,
  faAddressBook,
  faAt,
  faCaretDown,
  faCaretUp
} from '@fortawesome/free-solid-svg-icons';
import { ResoucesService } from 'src/app/services/resouces.service';

@Component({
  selector: 'app-share-resources-form',
  templateUrl: './share-resources-form.component.html',
  styleUrls: ['./share-resources-form.component.scss']
})
export class ShareResourcesFormComponent implements OnInit {
  ShareResourcesForm: FormGroup;
  dropdownOptions = ['Text', 'Link'];
  iconSubject = faAddressBook;
  iconUserCircle = faUserCircle;
  iconEnvelope = faEnvelope;
  iconAt = faAt;
  iconCaretDown = faCaretDown;
  iconCaretUp = faCaretUp;

  isShowDropdown: boolean = false;

  fileName: File;

  constructor(private snackBar: MatSnackBar, private resourceService: ResoucesService) {
    this.setShareResourceForm();
  }

  ngOnInit(): void { }

  onSelectResourceType(resourceType) {
    this.ShareResourcesForm.controls.resourceType.setValue(resourceType);
  }

  setShareResourceForm() {
    this.ShareResourcesForm = new FormGroup({
      name: new FormControl(null),
      lastName: new FormControl(null),
      email: new FormControl(null),
      resourceType: new FormControl(null),
      resourceName: new FormControl(null),
      resourceText: new FormControl(null),
      resourceLink: new FormControl(null),
      resourceAttachment: new FormControl(null),
      fileSource: new FormControl(null)
    });
  }

  async onSubmit() {
    //console.log(this.ShareResourcesForm.controls.fileSource.value);
    //const formData = new FormData();
    //formData.append("file", this.ShareResourcesForm.controls.fileSource.value, this.ShareResourcesForm.controls.fileSource.value.name);
    //console.log("FORM DATA", formData.getAll("file"));

    //console.log(this.ShareResourcesForm.controls.fileSource.value.name);
    const body = {
      name: `${this.ShareResourcesForm.controls.name.value?.trim() || ""} ${this.ShareResourcesForm.controls.lastName.value?.trim() || ""}`,
      formType: 'Share Resources Form',
      fromEmail: this.ShareResourcesForm.controls.email.value,
      resourceType: this.ShareResourcesForm.controls.resourceType.value,
      resourceContent: this.ShareResourcesForm.controls.resourceText.value || this.ShareResourcesForm.controls.resourceLink.value || this.ShareResourcesForm.controls.resourceAttachment.value,
      // fileName: this.ShareResourcesForm.controls.fileSource.value?.name,
      // fileType: this.ShareResourcesForm.controls.fileSource.value?.type,
      // fileData: formData
    }

    const emailRes = await this.resourceService.sendEmail(body);

    this.openSnackBar(emailRes.successful);

    if (emailRes.successful) {
      Object.keys(this.ShareResourcesForm.controls).forEach((key) => {
        const control = this.ShareResourcesForm.controls[key];
        control.markAsPristine();
        control.markAsUntouched();
        control.clearValidators();
      });
      this.ShareResourcesForm.reset();
    }
  }

  onFileChange($event) {
    this.fileName = $event.target.files[0];

    if ($event.target.files.length > 0) {
      const file = $event.target.files[0];
      console.log(file.rawFile)
      this.ShareResourcesForm.patchValue({
        fileSource: file
      });
    }

    //console.log(this.ShareResourcesForm.controls.fileSource.value)
  }

  openSnackBar(isSuccess) {
    this.snackBar.open(`${isSuccess ? 'Your message has been successfully submitted.' : "We're sorry. There was a problem sending your message. Please try again."}`,
      "", {
      horizontalPosition: 'right',
      verticalPosition: 'top',
      duration: 3 * 1000,
      panelClass: isSuccess ? "red-text" : ""
    })
  }
}
