<div class="wrapper-center" *ngIf="!isLoading; else loading">
  <div class="body">

    <div class="results">
      <section class="operations-wrapper">
        <div class="operations-container">
          <span class="header">Search results for <strong>{{ searchText }}</strong> - <span class="count filterBy">{{
              filteredArticles.length }}/{{
              articlesOriginal.length
              }}
              Articles</span></span>
          <div class="operations">
            <form [formGroup]="SortField" class="sort">

              <!-- <mat-form-field appearance="outline">
              <mat-label>Sort By </mat-label>
              <mat-select [formControl]="SortField.sort">
                <mat-option *ngFor="let option of items" [value]="option.label">
                  {{option.label}}
                </mat-option>
              </mat-select>
            </mat-form-field> -->

              <span class="filterBy">Sort By </span>
              <div ngbDropdown role="group" aria-label="Button group with nested dropdown"
                (clickElsewhere)="isShowDropdown = false">
                <div (click)="isShowDropdown = !isShowDropdown" ngbDropdownToggle>
                  <input type="text" class="form-control" id="sort" formControlName="sort" />
                  <fa-icon *ngIf="!isShowDropdown" class="fa-lg" [icon]="iconCaretDown"></fa-icon>
                  <fa-icon *ngIf="isShowDropdown" class="fa-lg" [icon]="iconCaretUp"></fa-icon>
                </div>
                <div class="dropdown-menu" ngbDropdownMenu>
                  <span ngbDropdownItem *ngFor="let option of items"
                    (click)="changeSortCriteria(option); isShowDropdown = !isShowDropdown">
                    {{ option.label }}
                  </span>
                </div>
              </div>
            </form>
          </div>

          <div class="tags-container-small">
            <div class="tags" *ngIf="!isLoading">
              <mat-accordion id="filter-elem" #accordionElem [displayMode]="'display'" [multi]="true">
                <mat-expansion-panel #pannelSort>
                  <mat-expansion-panel-header>
                    <mat-panel-title><span class="i-screens filterBy">Sort by&nbsp;</span>
                      <form [formGroup]="SortField" class="sort">
                        <input type="text" class="form-control" id="sort" formControlName="sort" readonly />
                      </form>
                    </mat-panel-title>

                  </mat-expansion-panel-header>
                  <section>
                    <div class="sort-option" *ngFor="let option of items"
                      [ngClass]="{'selected':SortField.controls.sort.value === option.label}"
                      (click)="changeSortCriteria(option);">
                      {{ option.label }}
                    </div>
                  </section>
                </mat-expansion-panel>

                <mat-expansion-panel #pannelTags [expanded]="isPanelTagsOpen" (opened)="isPanelTagsOpen = true"
                  (closed)="isPanelTagsOpen = false">
                  <mat-expansion-panel-header>
                    <mat-panel-title><span class="i-screens filterBy">Filter by TAGS</span></mat-panel-title>
                  </mat-expansion-panel-header>
                  <section class="example-section">
                    <span class="example-list-section">
                      <mat-checkbox class="example-margin" [checked]="allCompleteTags" [color]="tag.color"
                        [indeterminate]="someComplete(tag)" (change)="
                          selectDeselectAll($event.checked, tag, 'tag')">
                        {{ tag.name | uppercase }}
                      </mat-checkbox>
                    </span>
                    <span class="example-list-section">
                      <ul>
                        <li *ngFor="let subtag of tag.subtags">
                          <mat-checkbox [(ngModel)]="subtag.completed" [color]="subtag.color"
                            (ngModelChange)="handleOnFilter(tag, 'tag')" ngDefaultControl>
                            {{ subtag.name | uppercase }}
                          </mat-checkbox>
                        </li>
                      </ul>
                    </span>
                  </section>
                </mat-expansion-panel>

                <mat-expansion-panel #pannelMediums [expanded]="isPanelOpen" (opened)="isPanelOpen = true"
                  (closed)="isPanelOpen = false">
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      <span class="i-screens filterBy">Filter by MEDIUMS</span>
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <section class="example-section">
                    <span class="example-list-section">
                      <mat-checkbox class="example-margin" [checked]="allCompleteMediums" [color]="medium.color"
                        [indeterminate]="someComplete(medium)"
                        (change)="selectDeselectAll($event.checked, medium, 'medium')">
                        {{ medium.name | uppercase }}
                      </mat-checkbox>
                    </span>
                    <span class="example-list-section">
                      <ul>
                        <li *ngFor="let subtag of medium.subtags">
                          <mat-checkbox [(ngModel)]="subtag.completed" [color]="subtag.color"
                            (ngModelChange)="handleOnFilter(medium, 'medium')" ngDefaultControl>
                            {{ subtag.name | uppercase }}
                          </mat-checkbox>
                        </li>
                      </ul>
                    </span>
                  </section>
                </mat-expansion-panel>
              </mat-accordion>
            </div>
          </div>

        </div>
      </section>

      <section class="main-body">
        <div class="left-spacer">

        </div>
        <div class="card-wrapper" *ngIf="filteredArticles.length; else noResults">
          <div class="card-container">
            <app-long-card *ngFor="let article of filteredArticles" (tagEvent)="handleTagClick();"
              [articleName]="article.articleName" [description]="article.description" [reviewAvg]="article.review || 0"
              [articleLink]="article.articleLink" [reviewAvg]="article.ratingAvg || 0"
              [reviewCount]="article.reviewCount || 0" [Tags]="article.Tags" [articleId]="article.id"
              [ratingTotal]="article.ratingCount || 0" [publishedOn]="article.createdAt"
              [comments]="article.comments"></app-long-card>
          </div>
        </div>
        <div class="tags-container">
          <div class="tags" *ngIf="!isLoading">
            <mat-accordion id="filter-elem" #accordionElem [displayMode]="'display'" [multi]="true">
              <mat-expansion-panel #pannelTags [expanded]="isPanelTagsOpen" (opened)="isPanelTagsOpen = true"
                (closed)="isPanelTagsOpen = false">
                <mat-expansion-panel-header>
                  <mat-panel-title> Filter by TAGS</mat-panel-title>
                </mat-expansion-panel-header>
                <section class="example-section">
                  <span class="example-list-section">
                    <mat-checkbox class="example-margin" [checked]="allCompleteTags" [color]="tag.color"
                      [indeterminate]="someComplete(tag)" (change)="
                        selectDeselectAll($event.checked, tag, 'tag')">
                      {{ tag.name | uppercase }}
                    </mat-checkbox>
                  </span>
                  <span class="example-list-section">
                    <ul>
                      <li *ngFor="let subtag of tag.subtags">
                        <mat-checkbox [(ngModel)]="subtag.completed" [color]="subtag.color"
                          (ngModelChange)="handleOnFilter(tag, 'tag')" ngDefaultControl>
                          {{ subtag.name | uppercase }}
                        </mat-checkbox>
                      </li>
                    </ul>
                  </span>
                </section>
              </mat-expansion-panel>

              <mat-expansion-panel #pannelMediums [expanded]="isPanelOpen" (opened)="isPanelOpen = true"
                (closed)="isPanelOpen = false">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    Filter by MEDIUMS
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <section class="example-section">
                  <span class="example-list-section">
                    <mat-checkbox class="example-margin" [checked]="allCompleteMediums" [color]="medium.color"
                      [indeterminate]="someComplete(medium)"
                      (change)="selectDeselectAll($event.checked, medium, 'medium')">
                      {{ medium.name | uppercase }}
                    </mat-checkbox>
                  </span>
                  <span class="example-list-section">
                    <ul>
                      <li *ngFor="let subtag of medium.subtags">
                        <mat-checkbox [(ngModel)]="subtag.completed" [color]="subtag.color"
                          (ngModelChange)="handleOnFilter(medium, 'medium')" ngDefaultControl>
                          {{ subtag.name | uppercase }}
                        </mat-checkbox>
                      </li>
                    </ul>
                  </span>
                </section>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
        </div>
        <ng-template #noResults>
          <em class="no-results">We're sorry. We cannot find any matches for your search term.</em>
        </ng-template>
      </section>
    </div>
  </div>
</div>

<ng-template #loading>
  <mat-progress-spinner mode="indeterminate" diameter='30' strokeWidth='8'></mat-progress-spinner>
</ng-template>