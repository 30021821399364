import {
  Component,
  ViewChild
} from '@angular/core';

import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Virtual,
  Zoom,
  Autoplay,
  Thumbs,
  Controller,
  EffectFade
} from 'swiper';
import { SwiperComponent } from 'swiper/angular';

// // install Swiper modules
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Zoom, EffectFade, Autoplay, Virtual]);

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss']
})

export class CarouselComponent {
  images: string[] = ["../../../assets/img/01_Stock.jpg", "../../../assets/img/02_Stock.jpg", "../../../assets/img/03_Stock.jpg", "../../../assets/img/04_Stock.jpg", "../../../assets/img/05_Stock.jpg", "../../../assets/img/06_Stock.jpg", "../../../assets/img/07_Stock.jpg", "../../../assets/img/08_Stock.jpg"];
  //images = [944, 1011, 984].map((n) => `assets/img/Logo-for-Carousel.png`);
  constructor() { }


  // @ViewChild('swiper', { static: false }) swiper?: SwiperComponent;

  // handleResume(){

  // }

  // handlePause(){
  //   this.swiper.pause
  // }

}
