<mat-card class="newsletter-wrapper" id="Newsletters">
    <div class="container">
        <mat-accordion class="accordion">
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Current and Archived Newsletters
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <!-- <button mat-stroked-button>Introductory Newsletter - 2023</button> -->
                <a href="https://0v166.mjt.lu/nl2/0v166/5r6lu.html?hl=en" rel="noreferrer noopener" target="_blank" class="link-button">Introductory Newsletter - 2023</a>
            </mat-expansion-panel>
        </mat-accordion>
        <div class="newsletter-main"><img width="100%" height="auto" src="../../../assets/img/newsletter_banner.png" />
        </div>
        <div class="title flex-v center">
            <h2><strong>HeRe for WHSCI</strong></h2>
            <h2><strong>He</strong>althcare <strong>Re</strong>sources for <strong>W</strong>omen with
                <strong>SCI</strong>
            </h2>
            <h4>Women’s Health after SCI Newsletter</h4>
            <h4>Introductory Newsletter - 2023</h4>
        </div>
        <div>
            <h5 class="main-subtitle"><strong>ACCESS TO HEALTHCARE INFORMATION</strong></h5>
            <div class="img-container"><img width="100%" height="auto" alt="Access to healthcare information image"
                    src="../../../assets/img/01_Stock.jpg" /></div>
            <p>Women living with a spinal cord injury (SCI) have a right to accessible healthcare, including accurate
                information, education, and other health related resources. However, such resources are challenging to
                find.
                Many women with SCI struggle to locate and access important health care, including free existing
                resources.
                This can have negative consequences on health and well-being. <strong>Our mission is to provide a
                    “one-stop”
                    website directory to help share resources that meet the unique needs of women with SCI while also
                    identifying gaps in information.</strong></p>
            <mat-card class="quote-box">
                <p><q>
                        <em> We often struggle just finding the right doctor, finding the right information. And then we
                            [don’t
                            get
                            information] about our needs. It's just incredible the amount of lack of understanding that
                            I
                            run
                            into.</em>
                    </q></p>
                <p class="align-r"><em>~quote from woman with SCI in focus group</em></p>
            </mat-card>
        </div>
        <div>
            <h5><strong>Introducing Healthcare Resources for Women with SCI (HeRe for Women)</strong></h5>


            <p><strong>Who are we?</strong> We are a collaborative network between TIRR Memorial Hermann and Mt. Sinai
                Health System with support
                from SCI Model System Centers and an advisory board of women with SCI and other stakeholders. We have
                joined efforts to develop a website directory of existing healthcare resources for women with SCI. This
                was made possible with funding from the Craig H. Neilsen Foundation (# 590114).
            </p>
            <p>
                <strong>How did we get started? </strong>We conducted focus groups, first with women with SCI and then
                with healthcare
                professionals who provide care to women with SCI. Through these focus groups, we identified important
                healthcare topics and chose the top five as the basis for this directory. This included overwhelming
                concern due to challenges with accessing information and services, education and guidance on advocacy,
                information specific for the needs of women with SCI on bowel care, bladder care, gynecological care,
                and mental health.
            </p>
            <div>
                <p><strong>What do we offer? </strong>Three main components: </p>
                <ol>
                    <li class="no-list-style">
                        <p class="no-margin"><strong>(1) Directory of Healthcare Resources for Women with SCI</strong>
                        </p>
                        <p>
                            This website is a searchable directory that lists existing, open-access, healthcare
                            resources that have been identified both nationally and internationally and that fall under
                            one of the five topics identified by the focus groups. The directory includes resources
                            specifically developed for women with SCI as well as information and resources for the
                            broader community of women with disabilities that may be of interest to women with SCI.
                        </p>
                        <p class="no-margin">
                            Below are links to information and resources for each of the 5 healthcare topics identified
                            by the focus groups:
                        </p>
                        <div class="links-container flex-v">
                            <a  href="#" [routerLink]="'/Resources/Access'">Access</a>
                            <a  href="#" [routerLink]="'/Resources/Advocacy'">Advocacy</a>
                            <a  href="#" [routerLink]="'/Resources/BladderBowel'">Bowel/Bladder</a>
                            <a  href="#" [routerLink]="'/Resources/Gynecology'">Gynecology</a>
                            <a  href="#" [routerLink]="'/Resources/MentalHealth'">Mental Health</a>
                        </div>

                        <p>
                            New resources are being developed every day, and HeRe for Women is constantly searching for
                            additional resources that are appropriate to share on our directory. This directory provides
                            a link to the resource to help consumers find the original source. We do not duplicate
                            materials. Our mission is to include links to consumer-based resources only. Academic and
                            professional materials not intended for consumer distribution are not included (e.g.
                            published articles, copyright property).
                        </p>
                        <p>
                            <strong>We need your help.</strong> If you know of any resources that may be of interest to
                            women with SCI
                            that you would like us to consider posting on the directory, please share them with us <a
                                href="#" [routerLink]="'/Network/Share'">here</a> or contact us directly by replying to
                            this email.
                        </p>
                    </li>
                    <li class="no-list-style">
                        <p class="no-margin">
                            <strong>(2) List of Healthcare Professionals Who Serve Women with SCI</strong>
                        </p>
                        <p> Women with SCI have shared that finding healthcare professionals who can meet their needs is
                            extremely challenging, with few providers having accessible offices/equipment and necessary
                            knowledge about their unique healthcare needs. We include a list of healthcare professionals
                            on the HeRe for WHSCI website. Women with SCI and professionals generated this list during
                            the development of this project. The list includes an option to search for providers
                            by zipcode and filters to organize by Speciality and state.</p>

                        <p>
                            We are committed to expanding this list of healthcare providers who serve women
                            with SCI. We invite you to share the names and contact information of healthcare
                            practitioners, across disciplines, who serve women with SCI on the website <a href="#"
                                [routerLink]="'/Network/Share'">here</a>. We
                            especially welcome personal recommendations from women with SCI. We ask you to please
                            consider who you might recommend to a friend and consider sharing their information with us.
                            We will contact the professional to check accessibility and appropriateness for inclusion
                            and to get their permission to be listed.
                        </p>
                    </li>
                    <li class="no-list-style">
                        <p class="no-margin">
                            <strong>(3) Network Membership</strong>
                        <p>
                            HeRe for Women also includes a listing of members of the Women’s Health after SCI Network
                            (WHSCI Network). The mission of the WHSCI Network is to share women’s health resources in an
                            effort to reduce the barriers women with SCI face finding and learning about healthcare
                            resources that meet their specific needs. The HeRe WHSCI Network is committed to
                            communicating and sharing links to resources that can be accessed in one directory.
                        </p>
                        <p>
                            <strong>Joining our Network.</strong> Are you interested in knowing about healthcare
                            resources for women with
                            SCI? If so, please join the WHSCI Network by completing the <a href="#"
                                [routerLink]="'/Network/JoinUs'">online application</a>. Women
                            with SCI, healthcare professionals, researchers, caregivers, friends, and family are welcome
                            to join. Membership is free.
                            Who should join? People interested in the health and wellness of women with SCI.
                        </p>
                        <p>
                            <strong>Who should join?</strong> People interested in the health and wellness of women with
                            SCI.

                        </p>
                        <p><strong>Benefits of network membership include</strong></p>
                        <ul>
                            <li>
                                Bi-annual newsletters updating members on new healthcare resources that are available on
                                important topics for women with SCI, including new listings on the HeRe for WHSCI
                                website
                                <ul>
                                    <li>
                                        These updates will also include requests for new resources identified or
                                        recently developed that would be ready and available for public sharing and
                                        dissemination.
                                    </li>
                                </ul>
                            </li>
                            <li>
                                Free listing of links to resources allowing easy dissemination of appropriate resources
                                on the HeRe for WHSCI website.
                                <ul>
                                    <li>
                                        To maintain privacy, we plan to include individual members’ names and limited
                                        information on the website as follows. For example, we will list members as
                                        follows:
                                        <p><strong>Heather Taylor, Texas, USA, TIRR Memorial Hermann and
                                                UTHealth.</strong></p>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                        <p>
                            We will keep your email contact private. Email will only be used to provide the bi-annual
                            updates and communication. You can terminate your membership and stop all communication at
                            any time by sending an email to <a
                                href="mailto:HeReforWomen@memorialhermann.org?subject=HeRe - Newsletter">HeReforWomen@memorialhermann.org</a>.
                        </p>
                    </li>
                </ol>
            </div>
            <div class="flex-h space-b margin-top-large">
                <img width="32.5%" height="auto" src="../../../assets/img/06_Stock.jpg" />
                <img width="32.5%" height="auto" src="../../../assets/img/05_Stock.jpg" />
                <img width="32.5%" height="auto" src="../../../assets/img/03_Stock.jpg" />
            </div>
            <div class="margin-top-large">
                <p>
                    The HeRe for WHSCI website was funded by a grant from the <a href="https://chnfoundation.org/"
                        rel="noreferrer noopener" target="_blank">Craig H. Neilsen Foundation</a> (grant
                    number
                    590114). We also gratefully acknowledge the support of the ACRM Women’s Health Task Force and the
                    SCI Model Systems Women’s Health Special Interest Group, funded by NIDILRR. We are especially
                    grateful to the women with SCI and stakeholders who collaborated with us on this project.
                </p>
            </div>
            <div class="disclaimer margin-top-large">
                Disclaimer: The links to publications, materials or websites of other organizations or entities does not
                constitute endorsement of such publications, materials, or websites. We provide these references and
                links because they may be of value to persons interested in SCI. The content provided on this website is
                not intended to substitute professional medical advice. Always consult your doctor or other qualified
                healthcare providers with any questions regarding a medical condition including diagnosis and treatment.
            </div>
        </div>
    </div>
</mat-card>