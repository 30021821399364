import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-meet-scidr',
  templateUrl: './meet-scidr.component.html',
  styleUrls: ['./meet-scidr.component.scss']
})
export class MeetSCIDRComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
