<mat-card class="form-container">
  <h2>Join Our Network</h2>
  <img class="divider" src="../../../assets/img/divider.png" />
  <p class="contactus-st small">
    Please join the HeRe WHN by completing this online application. Women with SCI, healthcare professionals, and
    caregivers are welcome to join. Membership is free. Emails will remain confidential. Network members will only receive bi-annual newsletters with resource updates.
  </p>

  <form [formGroup]="JoinNewsletterForm" (ngSubmit)="onSubmit($event)">
    <mat-form-field appearance="outline" [errorState]="true">
      <mat-label>Name </mat-label>
      <input matInput [formControl]="JoinNewsletterForm.controls.name" placeholder="Example: Dana">
      <mat-icon matSuffix>person</mat-icon>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Last Name </mat-label>
      <input matInput [formControl]="JoinNewsletterForm.controls.lastName" placeholder="Example: Foster">
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Title <em>- Optional</em></mat-label>
      <input matInput [formControl]="JoinNewsletterForm.controls.title" placeholder="Example: Dr.">
      <mat-icon matSuffix>title</mat-icon>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Email</mat-label>
      <input matInput [formControl]="JoinNewsletterForm.controls.email" placeholder="Example: dana_foster@gmail.com">
      <mat-icon matSuffix>email</mat-icon>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>I am a(n)</mat-label>
      <mat-select [formControl]="JoinNewsletterForm.controls.identity" multiple>
        <mat-option *ngFor="let option of dropdownOptions" [value]="option">
          {{option}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline" *ngIf="isOtherIdentity">
      <mat-label>Other</mat-label>
      <input matInput [formControl]="JoinNewsletterForm.controls.otherIdentity">
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Affiliation/Institution <em>- Optional</em></mat-label>
      <input matInput [formControl]="JoinNewsletterForm.controls.affiliation" placeholder="Example: TIRR">
      <mat-icon matSuffix>domain</mat-icon>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>What is your experience/interest in women’s health after SCI? <em>- Optional</em>
      </mat-label>
      <textarea matInput type="text" class="form-control" id="interest"
        [formControl]="JoinNewsletterForm.controls.interest" maxlength="300"></textarea>
    </mat-form-field>



    <section>
      <mat-checkbox class="checkbox" [formControl]="JoinNewsletterForm.controls.consentEmails"><strong>I consent to
          receiving bi-annual
          emails from the HeRe for Women's Health after SCI Network</strong></mat-checkbox>
    </section>

    <section>
      <mat-checkbox class="checkbox" [formControl]="JoinNewsletterForm.controls.consentIdentity"><strong>I consent to
          listing my name and
          affiliation on the HeRe for Women with SCI website </strong><em>- Optional</em></mat-checkbox>
    </section>

    <button class="btn btn-primary m-top" type="submit" [disabled]="!JoinNewsletterForm.valid">
      Submit
    </button>
  </form>
</mat-card>