import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { AboutComponent } from './pages/about/about.component';
import { ResourcesComponent } from './pages/resources/resources.component';
import { ContactUsComponent } from './pages/contact-us/contact-us.component';
import { PhysiciansComponent } from './pages/physicians/physicians.component';
import { NetworkComponent } from './pages/network/network.component';
import { CommonModule } from '@angular/common';
import { SearchResultsComponent } from './pages/search-results/search-results.component';
import { NewsletterComponent } from './pages/newsletter/newsletter.component';
import { MeetUsComponent } from './pages/meet-us/meet-us.component';
import { MeetSCIDRComponent } from './pages/meet-scidr/meet-scidr.component';
import { HealthcareResourcesComponent } from './pages/healthcare-resources/healthcare-resources.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/Home',
    pathMatch: 'full'
  },
  {
    path: 'Home',
    component: HomeComponent
  },
  {
    path: 'AboutUs/AbouttheSite',
    component: AboutComponent
  },
  {
    path: 'AboutUs/MeettheTeam',
    component: MeetUsComponent
  },
  {
    path: 'AboutUs/SCIDR',
    component: MeetSCIDRComponent
  },
  {
    path: 'Resources/:resourceType',
    component: ResourcesComponent,
    pathMatch: 'full'
  },
  {
    path: 'Network/Newsletters',
    component: NewsletterComponent
  },
  {
    path: 'Network/:networkType',
    component: NetworkComponent
  },
  {
    path: 'HealthcareProviders/ListofProviders',
    component: PhysiciansComponent,
  },
  {
    path: 'HealthcareProviders/ResourcesforProviders',
    component: HealthcareResourcesComponent,
  },
  {
    path: 'ContactUs',
    component: ContactUsComponent,
    pathMatch: 'full'
  },
  {
    path: 'SearchResults',
    component: SearchResultsComponent,
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [CommonModule, RouterModule.forRoot(routes, { anchorScrolling: "enabled", scrollOffset: [0, 158] })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
