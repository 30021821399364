import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './pages/home/home.component';
import { AboutComponent } from './pages/about/about.component';
import { ResourcesComponent } from './pages/resources/resources.component';
import { NetworkComponent } from './pages/network/network.component';
import { ContactUsComponent } from './pages/contact-us/contact-us.component';
import { PhysiciansComponent } from './pages/physicians/physicians.component';
import { CardComponent } from './components/card/card.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HeaderComponent } from './components/header/header.component';
import { LongCardComponent } from './components/long-card/long-card.component';
import { NgbModule, NgbRating, NgbRatingModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { RatingDialogComponent } from './components/rating-dialog/rating-dialog.component';
import { SortDropdownComponent } from './components/sort-dropdown/sort-dropdown.component';
import { SearchSelectComponent } from './components/search-select/search-select.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { ReactiveFormsModule } from '@angular/forms';
import { AutofocusDirective } from './directive/autofocus.directive';
import { StylePaginatorDirective } from './directive/style-paginator.directive';
import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import {
  MatPaginatorIntl,
  MatPaginatorModule
} from '@angular/material/paginator';
import { GoogleMapsModule } from '@angular/google-maps';

// import { NgxCarousel3dModule } from 'ngx-carousel-3d';

import { CommonModule } from '@angular/common';
// import { MatTableModule } from '@angular/material/table';
// import {
//   MatAutocompleteModule,
//   MatButtonModule,
//   MatButtonToggleModule,
//   MatCardModule,
//   MatCheckboxModule,
//   MatChipsModule,
//   MatDatepickerModule,
//   MatDialogModule,
//   MatDividerModule,
import { MatExpansionModule } from '@angular/material/expansion';
//   MatGridListModule,
//   MatIconModule,
//   MatInputModule,
//   MatListModule,
//   MatMenuModule,
//   MatNativeDateModule,
//   MatPaginatorModule,
//   MatProgressBarModule,
//   MatProgressSpinnerModule,
//   MatRadioModule,
//   MatRippleModule,
//   MatSelectModule,
//   MatSidenavModule,
//   MatSliderModule,
//   MatSlideToggleModule,
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTabsModule } from '@angular/material/tabs';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
//   MatSortModule,
//   MatStepperModule,
//   MatTableModule,
//   MatTabsModule,
//   MatToolbarModule,
//   MatTooltipModule
// } from '@angular/material';
import { CdkTableModule } from '@angular/cdk/table';
import { MatTableModule } from '@angular/material/table';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSortModule } from '@angular/material/sort';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { ContactCardComponent } from './components/contact-card/contact-card.component';
import { ContactFormComponent } from './components/contact-form/contact-form.component';
import { JoinNewsletterFormComponent } from './components/join-newsletter-form/join-newsletter-form.component';
import { ShareResourcesFormComponent } from './components/share-resources-form/share-resources-form.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CarouselComponent } from './components/carousel/carousel.component';
import { SearchBarComponent } from './components/search-bar/search-bar.component';
import { SearchResultsComponent } from './pages/search-results/search-results.component';
import { ArticlesResultComponent } from './components/articles-result/articles-result.component';
import { SharedIconComponent } from './components/shared-icon/shared-icon.component';
import { FooterComponent } from './components/footer/footer.component';
import { ClickElsewhereDirective } from './directive/click-elsewhere.directive';
import { ResoucesService } from './services/resouces.service';
import { NewsletterComponent } from './pages/newsletter/newsletter.component';
import { PhysiciansAboutComponent } from './pages/physicians-about/physicians-about.component';
// import { carousel } from 'angular-carousel-3d';
import { SwiperModule } from "swiper/angular";
import { MeetUsComponent } from './pages/meet-us/meet-us.component';
import { LazyImgDirective } from './directive/lazy-img.directive';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MeetSCIDRComponent } from './pages/meet-scidr/meet-scidr.component';
import { MatSidenavModule } from "@angular/material/sidenav";
import { HealthcareResourcesComponent } from './pages/healthcare-resources/healthcare-resources.component';

export function CustomPaginator() {
  const customPaginatorIntl = new MatPaginatorIntl();

  customPaginatorIntl.itemsPerPageLabel = 'Resources per page:';

  return customPaginatorIntl;
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    AboutComponent,
    ResourcesComponent,
    NetworkComponent,
    ContactUsComponent,
    PhysiciansComponent,
    CardComponent,
    HeaderComponent,
    LongCardComponent,
    SortDropdownComponent,
    SearchSelectComponent,
    AutofocusDirective,
    StylePaginatorDirective,
    ClickElsewhereDirective,
    ContactCardComponent,
    ContactFormComponent,
    JoinNewsletterFormComponent,
    ShareResourcesFormComponent,
    CarouselComponent,
    SearchBarComponent,
    SearchResultsComponent,
    ArticlesResultComponent,
    SharedIconComponent,
    FooterComponent,
    NewsletterComponent,
    PhysiciansAboutComponent,
    MeetUsComponent,
    LazyImgDirective,
    MeetSCIDRComponent,
    HealthcareResourcesComponent,

  ],
  entryComponents: [RatingDialogComponent],
  imports: [
    ScrollingModule,
    ReactiveFormsModule,
    BrowserModule,
    FontAwesomeModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    NgbModule,
    ReactiveFormsModule,
    MatTableModule,
    MatButtonModule,
    MatPaginatorModule,
    MatExpansionModule,
    MatMenuModule,
    MatIconModule,
    FontAwesomeModule,
    CdkTableModule,
    MatButtonModule,
    MatCardModule,
    MatIconModule,
    MatPaginatorModule,
    MatAutocompleteModule,
    MatTooltipModule,
    MatSortModule,
    MatTabsModule,
    MatTableModule,
    MatToolbarModule,
    MatCheckboxModule,
    MatSelectModule,
    MatSidenavModule,
    MatButtonModule,
    MatButtonToggleModule,
    OverlayModule,
    PortalModule,
    MatIconModule,
    MatInputModule,
    NgbRatingModule,
    GoogleMapsModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    SwiperModule
  ],
  providers: [{ provide: MatPaginatorIntl, useValue: CustomPaginator() }, ResoucesService],
  bootstrap: [AppComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ]

})
export class AppModule { }
