<div class="physicians-wrapper">
  <mat-card class="physicians-container main-container-wrapper" id="AboutHealthcareResources">
    <div class="intro">
    <h2 class="title">Healthcare Providers</h2>
    <img class="divider" src="../../../assets/img/divider.png" />
    <p>
      This directory includes a listing of healthcare providers who offer services for women
      with SCI. They were recommended by women with SCI, other healthcare
      professionals, or caregivers. Some of the healthcare professionals
      included in this directory are members of the NIDILRR-funded
      <a href="https://msktc.org/sci/model-system-centers" target="_blank">Spinal Cord Injury Model System</a>
      (SCIMS) and are part of the SCIMS Women’s Health Task Force. As healthcare
      providers at SCIMS sites, they have experience treating patients with SCI.
    </p>
    <p>
      If you would like to add a healthcare provider to our list of resources, please email us directly at <a
      href="mailto:HeReforWomen@memorialhermann.org?subject=HeRe - Healthcare Resource">HeReforWomen@memorialhermann.org</a>. We will confirm the resource and add them to our provider list.
    </p>
  
  </div>
    <mat-card class="map-card-wrapper" id="HealthcareProvidersResources">
      <div class="physicians-body">
        <section class="cards-container">
          <div class="cards-map">
            <div class="physicians-cards" *ngIf="physiciansInfo.length; else noResults">
              <app-contact-card *ngFor="let card of physiciansInfo" [id]="card.id" [doctorName]="card.DoctorName" [city]="card.City"
                [hospitalName]="card.HospitalName" [phone]="card.Phone" [Speciality]="card.Speciality"
                [address]="card.Street" [website]="card.Website" [zip]="card.Zip" [isActive]="card.id === activeId"
                type="physicians" (clickCard)="onClickCard($event)"></app-contact-card>

            </div>
            <ng-template #noResults>
              <div class="no-results">
              <p><em>We're sorry. We cannot find any matches for your search term.</em></p></div>
            </ng-template>
          </div>
        </section>
        <section class="map">
          <div class="filter-container">
            <!-- <div class="zipCodeSearch">
          <label for="zip-code">Search Zip Code</label>
          <input
            type="number"
            class="form-control"
            id="zip-code"
            maxLength="5"
            (keyup.enter)="onZipCodeSearch(zipCode)"
            #zipCode
          />
          <button (click)="onZipCodeSearch(zipCode)">
            <fa-icon
              class="search-icon"
              [icon]="searchIcon"
              size="md"
            ></fa-icon>
          </button> -->

            <!-- <div class="dropdown-menu" ngbDropdownMenu>
            <span
              ngbDropdownItem
              *ngFor="let option of items"
              (click)="
                changeSortCriteria(option);
                isShowDropdown = !isShowDropdown
              "
            >
              {{ option.label }}
            </span>
          </div> -->
            <!-- </div> -->
            <div class="filterBy-text filterBy">Filter by</div>
            <div class="filterDd Speciality">
              <label class="filterBy">Speciality</label>
              <div ngbDropdown role="group" aria-label="Button group with nested dropdown"
                (clickElsewhere)="isShowDropdown = false" class="dropdown">
                <div (click)="isShowDropdown = !isShowDropdown" ngbDropdownToggle>
                  <input type="text" class="filterSpeciality" class="form-control" id="sort"
                    [value]="selectedSpeciality || ''"/>
                  <fa-icon *ngIf="!isShowDropdown" class="fa-lg" [icon]="iconCaretDown"></fa-icon>
                  <fa-icon *ngIf="isShowDropdown" class="fa-lg" [icon]="iconCaretUp"></fa-icon>
                </div>
                <div class="dropdown-menu" ngbDropdownMenu>
                  <span ngbDropdownItem *ngFor="let option of SpecialityList" (click)="
                  filterBySpeciality(option.Speciality);
                  isShowDropdown = !isShowDropdown
                ">
                    {{ option.Speciality }}
                  </span>
                </div>
              </div>
            </div>

            <div class="filterDd">
              <label class="filterBy">City, State</label>
              <div ngbDropdown role="group" aria-label="Button group with nested dropdown"
                (clickElsewhere)="isShowDropdown = false" class="dropdown">
                <div (click)="isShowDropdown = !isShowDropdown" ngbDropdownToggle>
                  <input type="text" class="filterSpeciality" class="form-control" id="sort"
                    [value]="selectedState || ''" />
                  <fa-icon *ngIf="!isShowDropdown" class="fa-lg" [icon]="iconCaretDown"></fa-icon>
                  <fa-icon *ngIf="isShowDropdown" class="fa-lg" [icon]="iconCaretUp"></fa-icon>
                </div>
                <div class="dropdown-menu-state" ngbDropdownMenu>
                  <span ngbDropdownItem *ngFor="let option of stateList" (click)="
                  filterByState(option.City); isShowDropdown = !isShowDropdown
                ">
                    {{ option.City }}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <google-map height="90%" width="100%" [zoom]="zoom" [center]="center" [options]="options">
            <map-marker *ngFor="let marker of markers" [position]="marker.position" [label]="marker.label"
              [title]="marker.title" [options]="marker.options">
            </map-marker>
          </google-map>

          <button (click)="zoomIn()">Zoom in</button>
          <button (click)="zoomOut()">Zoom out</button>
        </section>
      </div>
    </mat-card>
  </mat-card>
</div>