
<div class="wrapper-center">
  <section class="home-wrapper" id="homePage">
    <div class="carousel-container">
      <app-carousel></app-carousel>
    </div>
    <div class="section-divider">
      <mat-card class="card-wrapper">
        <h2>Explore Resources</h2>
        <img class="divider" src="../../../assets/img/divider.png" />
        <section class="card-container">
          <app-card (click)="cardRoute($event, resource.value.topic)"
           *ngFor="let resource of resources | keyvalue"
            [title]="resource.value.topic" [img]="resource.value.img">
          </app-card>
        </section>
      </mat-card>
    </div>
  </section>
</div>

<!-- <div><input type="file" (change)="onFileChange($event)" />
 <button type="submit" (click)="onSubmit($event)">submit</button> </div> -->