<mat-card class="wrapper" id="MeettheTeam">
    <div class="container">
        <h2 class="about-title">Meet the Team</h2>
        <img class="divider" src="../../../assets/img/divider.png" />
        <p class="about-text">
            The HeRe team members listed below were part of the team of people who worked on the Craig H. Neilsen
            project
            that created this resource for women with SCI. The SCIDR team at TIRR Memorial Hermann maintains and updates
            the site regularly.
        </p>
        <mat-card class="info-card-wrapper">
            <mat-card-content class="info-card-body">
                <div class="card-img">
                    <img src="../../../assets/img/HeatherTaylor.jpg" height="227" width="auto" />
                </div>
                <span>
                    <p>
                        <strong>Heather Taylor, Ph.D.</strong>
                        is the Director for Spinal Cord Injury and Disability Research (SCIDR) at TIRR Memorial Hermann
                        and
                        Associate
                        Professor for Physical Medicine and Rehabilitation at UTHealth. She also holds an adjunct
                        faculty
                        appointment
                        at Baylor College of Medicine. She is the former Chair for both the Spinal Cord Injury Model
                        Systems and
                        the
                        American Congress for Rehabilitation Medicine (ACRM) Special Interest Group (SIG) Women’s Health
                        Task
                        Force
                        focusing on the unique concerns of women’s health after spinal cord injury (SCI). Dr. Taylor has
                        focused
                        her
                        research on health, motor recovery and learning, and psychosocial functioning with a strong
                        emphasis on
                        women
                        and children with SCI. Dr. Taylor has a long history conducting research with a mission to
                        improve the
                        lives
                        of people with disabilities and has been Principal Investigator/Co-Investigator on over 30
                        funded
                        projects.
                    </p>
                </span>
            </mat-card-content>

        </mat-card>

        <mat-card class="info-card-wrapper">
            <mat-card-content class="info-card-body">
                <div class="card-img">
                    <img src="../../../assets/img/ThomasBryce.jpg" height="185" width="auto" class="xs-img-height" />
                </div>
                <span>
                    <p>
                        <strong>Thomas N. Bryce, M.D.</strong>
                        is an internationally recognized expert in spinal cord injury (SCI) rehabilitation with a
                        special interest in the assessment and treatment of pain after SCI. Dr. Bryce is the Medical
                        Director of the SCI Program at Mount Sinai in New York, the Director of the Mount Sinai SCI
                        Research Center, and the Project Director for the Mount Sinai SCI Model System. Dr. Bryce’s
                        research has been funded by the Department of Health and Human Services, the Department of
                        Education, the Department of Defense, the Craig H. Neilsen Foundation, the Christopher & Dana
                        Reeve Foundation, and the Paralyzed Veterans of America.
                    </p>
                </span>
            </mat-card-content>
        </mat-card>

        <mat-card class="info-card-wrapper">
            <mat-card-content class="info-card-body">
                <div class="card-img">
                    <img src="../../../assets/img/SusanRobinsonWhelen.jpg" height="227" width="auto" />
                </div>
                <span>
                    <p>
                        <strong>Susan Robinson-Whelen, Ph.D.</strong>
                        is a Scientist at TIRR Memorial Hermann’s Spinal Cord Injury and Disability Research Center
                        (SCIDR),
                        Senior Investigator at Baylor College of Medicine’s (BCM) Center for Research on Women with
                        Disabilities
                        (CROWD), and Assistant Professor at BCM’s H. Ben Taub Department of Physical Medicine and
                        Rehabilitation.
                        For the past 25 years, she has conducted research on the health and well-being of people with
                        disabilities, primarily women with mobility impairments, including spinal cord injury.
                    </p>
                </span>
            </mat-card-content>
            <span>

            </span>
        </mat-card>

        <mat-card class="info-card-wrapper">
            <mat-card-content class="info-card-body">
                <div class="card-img">
                    <img src="../../../assets/img/SarahHoffman.jpg" height="227" width="auto" />
                </div>
                <span>
                    <p>
                        <strong>Sarah Hoffman, B.A.</strong>
                        is a Research Assistant at TIRR Memorial Hermann’s Spinal Cord Injury and Disability Research Center (SCIDR). She has worked on multiple research projects focusing on
                        aspects of
                        health and wellness after Spinal Cord Injury (SCI), including the SCI Model Systems. In
                        addition,
                        Ms.
                        Hoffman attends monthly meetings of the ACRM ISIG SCI Women’s Health Task Force. She coordinated
                        the
                        Craig Neilsen Creating Opportunities and Independence (CO&I) project, HeRe for Women with SCI, and was instrumental in all aspects
                        of the
                        development and implementation of the project.
                    </p>
                </span>
            </mat-card-content>

        </mat-card>

        <mat-card class="info-card-wrapper">
            <mat-card-content class="info-card-body">
                <div class="card-img">
                    <img src="../../../assets/img/AndrewDelgado.png" height="227" width="auto" />
                </div>
                <span>
                    <p>
                        <strong>Andrew Delgado, M.S.</strong>
                        is a Clinical Research Coordinator at the Spinal Cord Injury Research Center at Mount Sinai
                        Hospital in
                        New York City and a Ph.D. in Clinical Research Candidate at the Icahn School of Medicine at
                        Mount
                        Sinai.
                    </p>
                </span>
            </mat-card-content>
        </mat-card>
    </div>
</mat-card>