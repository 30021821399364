import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { filterOptions } from 'mock-data';
import { ResoucesService } from 'src/app/services/resouces.service';
import * as XLSX from "xlsx";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  resources = filterOptions;
  options = { fullWidth: false };

  hrefs = ['one', 'two', 'three', 'four', 'five'];
  constructor(private resourceService: ResoucesService, private _router: Router) {
  }

  title = 'XlsRead';
  file: File
  arrayBuffer: any;
  filelist: any;
  articlesList: any[] = [];

  async ngOnInit() {
    //     let body = {
    //       "topicName": "Bowel & Bladder",
    //       "topicAlias": "BAB",
    //       "longDescription": "<strong>Bladder</strong>|Depending on the level at which a spinal cord injury occurs, damage to the sacral micturition nerves can limit the ability to urinate or know when the bladder is full <cite><a href='https://msktc.org/lib/docs/Factsheets/SCI_Bladder_Health.pdf' target='_blank'>1</a></cite>. The bladder may contract uncontrollably or become too full and can cause incontinence (leaking/accidents).|It is important to find a bladder management program with your doctor that is effective for your lifestyle and limits infections (like UTIs) and maintains kidney function. There are both surgical and non-surgical methods of bladder management, which can often be chosen based on your lifestyle, motor skills, medical history and preferences.', 'Women certainly have unique needs when it comes to bladder management, and hopefully the resources on this website can equip you with the tools to address those concerns with your healthcare provider and find what works best for you.', '<strong>Bowel</strong>', 'Bowel complications are common among individuals with spinal cord injury. When the nerves that allow a person to control their bowel are damaged, the result is a neurogenic bowel. This affects the body’s process of storing and removing waste, which can lead to bowel incontinence (leaking/accidents), constipation, or abdomen pain.<cite><a href='https://msktc.org/sci/factsheets/Bowel_Function' target='_blank'>2</a></cite>', 'Finding an effective bowel program method with your physician and caregiver is important in preventing further complications. Scheduling, diet, medication and techniques are all a part of discovering the most effective program for you."
    //   }
    //    const test = await this.resourceService.setTopic(body);
    //    console.log(test)

    //    let body2 = {
    //     "topicName": "OB/GYN",
    //     "topicAlias": "GNC",
    //     "longDescription": "Gynecologic health refers to the health of the sexual and reproductive system of female bodies. Representing less than 25% of all people with spinal cord injury (SCI), women with SCI may find it more difficult to have their needs addressed and their questions answered about their gynecologic health.|Your gynecologic healthcare needs differ depending on your stage in life, but across the life span, gynecologic healthcare includes health screenings such as PAP tests and mammograms. Research has shown that women with SCI are less likely to receive such health screenings than women without disabilities <cite><a target='_blank' href='https://doi.org/10.1080/10790268.2016.1153293'></a></cite>. It is important for women with SCI to understand screening guidelines and recommendations and advocate to receive routine accessible screening services.|Gynecologic health encompasses menstruation and menstrual management; sexuality and sexual function; contraception; pregnancy, delivery, and post-partum changes; and menopause.  Women with SCI have unique needs with regard to all of these aspects of gynecologic health. Resources on the HeRe for Women with SCI website address each of these aspects of gynecologic health.|While these topics may be uncomfortable for some women to discuss, we encourage you to discuss your gynecologic health and any gynecologic concerns with your healthcare provider. We recognize that many women will not have access to a gynecologist who is knowledgeable about SCI and experienced in treating women with SCI. You may find it necessary to help educate your doctor about the impact of SCI on your gynecologic health. We hope that this web resource will help prepare you for such conversations."
    // }

    // const test2= await this.resourceService.setTopic(body2);
    //    console.log(test2);

    //    let body3 = {
    //     "topicName": "Advocacy",
    //     "topicAlias": "AVC",
    //     "longDescription": "It is important for women with SCI to be strong self-advocates by speaking up for and taking action to promote, protect, and defend their interests and rights. This is true across all aspects of life and across the full spectrum of human needs, but it can be particularly important to ensuring health and healthcare needs are met. Advocating for your needs and rights can be challenging, but there are agencies and resources that can help. The links listed below on the <em> HeRe for Women website </em> provide information, tools, and resources to help people advocate for themselves and/or others. In addition, there are resources to support advocacy efforts to achieve systemic change in laws, policies, and practices that advance the interests of women with SCI."
    // }

    // const test3 = await this.resourceService.setTopic(body3);
    //    console.log(test3)

  }


  onFileChange(e) {
    this.file = e.target.files[0];
    let fileReader = new FileReader();
    fileReader.readAsArrayBuffer(this.file);
    fileReader.onload = (e) => {
      this.arrayBuffer = fileReader.result;
      let data = new Uint8Array(this.arrayBuffer);
      let arr = new Array();
      for (let i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);
      let bstr = arr.join("");
      let workbook = XLSX.read(bstr, { type: "binary" });
      let first_sheet_name = workbook.SheetNames[0];
      let worksheet = workbook.Sheets[first_sheet_name];
      console.log(XLSX.utils.sheet_to_json(worksheet, { raw: true }));
      let arraylist = XLSX.utils.sheet_to_json(worksheet, { raw: true });
      this.filelist = arraylist;
      console.log(JSON.stringify(this.filelist));

      this.filelist.forEach(article => {
        const tagList = article['Tags']?.split(",").map(item => { if(item.trim()) return item.trim()}) || [];

        console.log('tag LIST', tagList);

        let articleParam = {
          articleName: `${article['Link Name']}${article['Parent Site Name'] && ` - ${article['Parent Site Name']}`}`,
          articleLink: article['Link'],
          description: article['Notes']?.trim() || "",
          publishedOn: this.excelDateToJSDate(article['Published On']),
          ratingAvg: 0,
          topicAlias: article["Category"],
          tagList: tagList
        }

        this.articlesList.push(articleParam);
      });
      console.log(this.articlesList);
    }
  }

  cardRoute($event, topic){
    $event.stopPropagation();
    $event.preventDefault();

    this._router.navigate(["Resources", this.transformString(topic)])

  }

  excelDateToJSDate(date) {
    return new Date(Math.round((date - 25569) * 86400 * 1000 + (21600 * 1000)));
  }

  transformString = (menuItem) =>
  menuItem.replace(/\s|\/|&|/g, '');

  onSubmit(e) {
    e.preventDefault();
    this.articlesList.forEach(async article => {
      const res = await this.resourceService.setArticle(article);
      console.log(res)
    })
  }
}