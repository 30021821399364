import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { faCaretDown, faCaretUp, faSearch } from '@fortawesome/free-solid-svg-icons';
import { physiciansInfo } from 'mock-physicians-data';
import { MapService } from './map.service';

@Component({
  selector: 'app-physicians',
  templateUrl: './physicians.component.html',
  styleUrls: ['./physicians.component.scss']
})
export class PhysiciansComponent implements OnInit {
  zoom = 1;
  center: google.maps.LatLngLiteral;
  options: google.maps.MapOptions = {
    mapTypeId: 'hybrid',
    zoomControl: false,
    scrollwheel: false,
    disableDoubleClickZoom: true,
    maxZoom: 15,
    minZoom: 3
  };
  searchIcon = faSearch;
  physiciansInfo = physiciansInfo;
  ContactUsForm: FormGroup;

  markers = [];

  iconCaretDown = faCaretDown;
  iconCaretUp = faCaretUp;
  isShowDropdown: boolean = false;
  selectedSpeciality: string = 'All';
  selectedState: string = 'All';


  zipFilterList = [];
  stateList = [];
  SpecialityList = [];

  activeId: number = 0;

  constructor(
    private mapService: MapService
  ) {
    this.SpecialityList = [{ Speciality: 'All' }, ...this.getSpecialityList()];
    this.stateList = [{ City: 'All' }, ...this.getStateList()];
  }

  ngOnInit() {
    this.getMarkers();
    this.center = {
      lat: 41.850033,
      lng: -87.6500523,
    }
    // navigator.geolocation.getCurrentPosition(position => {
    //   console.log('POSS',position)

    //   this.center = {
    //     Lat: position.coords.latitude,
    //     Lng: position.coords.longitude
    //   };

    //   this.addMarker(this.center)
    // });

    // this.addMarker(this.center)
  }

  onZipCodeSearch(zipCode) {
    //physiciansInfo.sort((a,b)=> a-b);

    this.mapService.getZipCodes(zipCode.value).subscribe((res) => {
      if (res) {
        this.markers = [];
        const matchedZipcodes = res.zip_codes;
        this.zipFilterList = physiciansInfo.reduce((acc, curr) => {
          if (matchedZipcodes.find(res => res == curr.Zip)) {
            this.addMarker(curr);
            acc.push(curr);
          }
          return acc;
        }, []);

        if (this.selectedSpeciality) {
          this.filterBySpeciality(this.selectedSpeciality);
          return;
        }

        this.physiciansInfo = this.zipFilterList;
      }
    })


  }

  filterBySpeciality(Speciality) {
    this.selectedSpeciality = Speciality;
    this.markers = [];
    this.activeId = 0;

    this.physiciansInfo = physiciansInfo.filter(res => {
      if (this.selectedSpeciality === 'All') Speciality = res.Speciality;
      if (Speciality.includes(res.Speciality) && (res.City === ((this.selectedState === 'All' ? res.City : this.selectedState) || res.City))) {
        this.addMarker(res);
        return true;
      }
      return false
    });
  }

  filterByState(state) {
    this.selectedState = state;
    this.markers = [];
    this.activeId = 0;

    this.physiciansInfo = physiciansInfo.filter(res => {
      if (this.selectedState === 'All') state = res.City;
      if (res.City === state && (res.Speciality === ((this.selectedSpeciality === 'All' ? res.Speciality : this.selectedSpeciality) || res.Speciality))) {
        this.addMarker(res);
        return true;
      }
      return false
    });
  }

  getSpecialityList() {
    let memo = {};
    return this.physiciansInfo.filter(res => {
      if (!memo[res.Speciality]) {
        memo[res.Speciality] = true;
        return true;
      }
      return false;
    }).sort((a,b)=> a.Speciality.localeCompare(b.Speciality));
  }

  getStateList() {
    let memo = {};
    return this.physiciansInfo.filter(res => {
      if (!memo[res.City]) {
        memo[res.City] = true;
        return true;
      }
      return false;
    });
  }

  onClickCard(event) {
    this.activeId = event.id;
    this.markers = this.markers.map(marker => {
      if (marker.title === event.hospital) {
        marker.options = { animation: google.maps.Animation.BOUNCE }
      }
      else marker.options = { animation: null }
      return marker;
    })
  }

  getMarkers() {
    this.physiciansInfo.forEach((physician => {
      this.addMarker(physician);
    }))
  }

  addMarker(location) {
    this.markers.push({
      position: {
        lat: location.Lat,
        lng: location.Lng,
      },
      label: {
        color: 'red',
        text: `${location.Zip}`,
      },
      title: location.HospitalName,
      options: { animation: google.maps.Animation.DROP },
    });
  }

  zoomIn() {
    if (this.zoom < this.options.maxZoom) this.zoom++;
  }

  zoomOut() {
    if (this.zoom > this.options.minZoom) this.zoom--;
  }
}
