import { Component, HostListener, OnInit } from '@angular/core';
import { resources } from 'mock-data';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Location } from '@angular/common';

export interface navItems {
  navItemName: string;
  menuItems?: string[];
  arrowUp?: boolean;
}

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  resources = resources;
  active: string = 'Home';
  activeMenuOpt: string;
  navItems = [
    {
      navItemName: 'Home',
      fragment: "homePage",
      arrowUp: false
    },
    {
      navItemName: 'About Us',
      fragment: "aboutPage",
      arrowUp: false,
      menuItems: ['About the Site', 'Meet the Team', 'SCIDR']
    },
    {
      navItemName: 'Resources',
      fragment: "resourcesPage",
      menuItems: ['Access', 'Advocacy', 'Bladder & Bowel',  'Gynecology', 'Mental Health'],
      arrowUp: false
    },

    {
      navItemName: 'Healthcare Providers',
      fragment: "healthcarePage",
      arrowUp: false,
      menuItems: ['List of Providers','Resources for Providers']
    }, {
      navItemName: 'Network',
      fragment: "networkPage",
      arrowUp: false,
      menuItems: ['About Network','Join Us', 'Share', 'Newsletters']
    },
    {
      navItemName: 'Contact Us',
      fragment: "contactusPage",
      arrowUp: false
    }
  ];

  // @HostListener('window:scroll', ['$event'])
  // doSomething(event) {

  //   const winScroll = event.currentTarget.scrollTop;
  //   const height = event.currentTarget.scrollHeight - event.currentTarget.clientHeight;
  //   const scrolled = (winScroll / height) * 100;

  //   console.debug("Scroll %", scrolled);
  //   //document.getElementById("scrollBar").style.width = scrolled + "%";
  // }

  // @HostListener('window:scroll', ['$event']) onScroll(event): void {
  //   const elem = document.querySelector("#AboutSite") as HTMLAnchorElement | null;

  //   // console.log(elem.offsetHeight + 156)
  //   // console.log(window.pageYOffset);
  //   // console.log(window.pageYOffset, (elem.offsetHeight + 156))

  //   // if (window.pageYOffset < elem.offsetHeight) { this.location.replaceState('Home#homePage'); this.active = "Home" }

  //   // if(window.pageYOffset > elem.offsetHeight + 156) this.location.replaceState('/edit');
  // }

  constructor(private router: Router, private location: Location) {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(event => {
        if (event['url'].split('/')[2] === 'Newsletter') return this.active = 'Newsletter';
        if (event['url'].split('/')[2] === 'AboutResources') {
          this.active = 'HealthcareProviders';
          this.activeMenuOpt = event['url'].split('/')[2]; return;
        }
        this.active = event['url'].substring(1).split('/')[0] || 'Home';
        this.activeMenuOpt = event['url'].split('/')[2];
      });
  }

  transformString = (menuItem) =>
    menuItem.replace(/\s|\/|&|/g, '');

  ngOnInit(): void {
  }
}
