<ng-container *ngIf="filterOptions === []"> Loading tabs... </ng-container>

<mat-card id="{{routeFragment}}" class="resources-wrapper main-container-wrapper">
  <h2>Resources</h2>
  <img class="divider" src="../../../assets/img/divider.png" />
  <div class="banner">
    <p>The content provided on this website is for educational purposes only and is not a substitute for professional
      medical advice, diagnosis, or treatment. Always consult your doctor or other qualified healthcare providers with
      any
      questions regarding your health or any medical condition.</p>
  </div>
  <div class="resource-container">
    <div class="general">
      <mat-tab-group [selectedIndex]="selected.value" (selectedIndexChange)="selected.setValue($event)">
        <mat-tab *ngFor="let resource of filterOptions">
          <ng-template mat-tab-label (click)="setResource(resource)">
            <button class="tabButton" [routerLink]="['/Resources', transformString(resource)]"
              routerLinkActive="active">
              {{ resource | uppercase }}
            </button>
          </ng-template>

          <div #description class="description">
            <img src="{{'../../../assets/img/'+ headerImgMap[resource]}} " width="779" height="auto" />
            <br />
            <div *ngFor="let resourceBody of activeResource">
              <p *ngFor="let para of resourceBody.longDescription" [innerHTML]="para"></p>
            </div>
          </div>

          <div class="wrapper">
            <div class="articles" *ngIf="!isLoading; else loading">
              <div class="operations">
                <span class="count filterBy">{{ filteredArticles?.length || 0 }}/{{
                  selectedResource.articles?.length}}
                  Articles</span>
                <form [formGroup]="SortField" class="sort">
                  <span class="filterBy">Sort by</span>
                  <div ngbDropdown role="group" aria-label="Button group with nested dropdown"
                    (clickElsewhere)="isShowDropdown = false">
                    <div (click)="isShowDropdown = !isShowDropdown" ngbDropdownToggle>
                      <input type="text" class="form-control" id="sort" formControlName="sort" />
                      <fa-icon *ngIf="!isShowDropdown" class="fa-lg solid" [icon]="iconCaretDown"></fa-icon>
                      <fa-icon *ngIf="isShowDropdown" class="fa-lg solid" [icon]="iconCaretUp"></fa-icon>
                    </div>
                    <div class="dropdown-menu" ngbDropdownMenu>
                      <span ngbDropdownItem *ngFor="let option of items" (click)="
                          changeSortCriteria(option);
                          isShowDropdown = !isShowDropdown
                        ">
                        {{ option.label }}
                      </span>
                    </div>
                  </div>
                </form>
              </div>

              <div class="body">
                <!-- <span class="header">Resources</span> -->
                <div class="results">
                  <div class="absolute">
                    <div class="sticky" #sticky>
                      <div *ngIf="!isLoading">
                        <mat-accordion id="filter-elem" #accordionElem [displayMode]="'display'" [multi]="true">
                          <mat-expansion-panel #pannelTags [expanded]="isPanelTagsOpen"
                            (opened)="isPanelTagsOpen = true" (closed)="isPanelTagsOpen = false">
                            <mat-expansion-panel-header>
                              <mat-panel-title><span class="filterBy">Filter by&nbsp;</span>TAGS
                              </mat-panel-title>
                            </mat-expansion-panel-header>
                            <section class="example-section">
                              <span class="example-list-section">
                                <mat-checkbox class="example-margin" [checked]="allCompleteTags" [color]="tag.color"
                                  [indeterminate]="someComplete(tag)" (change)="
                        selectDeselectAll($event.checked, tag, 'tag')">
                                  {{ tag.name | uppercase }}
                                </mat-checkbox>
                              </span>
                              <span class="example-list-section">
                                <ul>
                                  <li *ngFor="let subtag of tag.subtags">
                                    <mat-checkbox [(ngModel)]="subtag.completed" [color]="subtag.color"
                                      (ngModelChange)="handleOnFilter(tag, 'tag')" ngDefaultControl>
                                      {{ subtag.name | uppercase }}
                                    </mat-checkbox>
                                  </li>
                                </ul>
                              </span>
                            </section>
                          </mat-expansion-panel>

                          <mat-expansion-panel #pannelMediums [expanded]="isPanelOpen" (opened)="isPanelOpen = true"
                            (closed)="isPanelOpen = false">
                            <mat-expansion-panel-header>
                              <mat-panel-title>
                                <span class="filterBy">Filter by &nbsp;</span>MEDIUMS
                              </mat-panel-title>
                            </mat-expansion-panel-header>
                            <section class="example-section">
                              <span class="example-list-section">
                                <mat-checkbox class="example-margin" [checked]="allCompleteMediums"
                                  [color]="medium.color" [indeterminate]="someComplete(medium)"
                                  (change)="selectDeselectAll($event.checked, medium, 'medium')">
                                  {{ medium.name | uppercase }}
                                </mat-checkbox>
                              </span>
                              <span class="example-list-section">
                                <ul>
                                  <li *ngFor="let subtag of medium.subtags">
                                    <mat-checkbox [(ngModel)]="subtag.completed" [color]="subtag.color"
                                      (ngModelChange)="handleOnFilter(medium, 'medium')" ngDefaultControl>
                                      {{ subtag.name | uppercase }}
                                    </mat-checkbox>
                                  </li>
                                </ul>
                              </span>
                            </section>
                          </mat-expansion-panel>
                        </mat-accordion>
                      </div>
                    </div>
                  </div>
                  <div class="card-container" *ngIf="filteredArticles.length; else noResults">
                    <app-long-card (tagEvent)="handleTagClick();" *ngFor="let article of filteredArticles"
                      [articleName]="article.articleName" [description]="article.description"
                      [articleLink]="article.articleLink" [reviewAvg]="article.ratingAvg || 0"
                      [reviewCount]="article.reviewCount || 0" [Tags]="article.Tags" [articleId]="article.id"
                      [ratingTotal]="article.ratingCount || 0" [publishedOn]="article.createdAt"
                      [comments]="article.comments">
                    </app-long-card>
                  </div>
                  <ng-template #noResults>
                    <div class="no-results">
                      <p >
                        <em>We're sorry. We cannot find any matches for your search term.</em>
                      </p>
                    </div>
                      
                  </ng-template>
                </div>
              </div>
            </div>

            <ng-template #loading>
              <mat-progress-spinner mode="indeterminate" diameter="30" strokeWidth="8"></mat-progress-spinner>
            </ng-template>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>

    <!-- <div class="paginator" [ngClass]="{ 'less-than-six': 10 >= filteredArticles.length}">
    <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" showFirstLastButtons>
    </mat-paginator>
  </div> -->
  </div>
</mat-card>