import { Component, OnInit } from '@angular/core';
import {
  faPhone,
  faLocationArrow,
  faMapPin,
  faEnvelope
} from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

export const disclaimers = {
  websiteDisclaimer: {
    title: "Website Disclaimer",
    body: "<p class='small'>The information provided by the HeRe for Women with SCI on <a>http://www.HeReforWomenSCI.com</a> is for general informational purposes only. All information on the Site is provided in good faith, however we make no representation or warranty of any kind, expressed or implied, regarding the accuracy, adequacy, validity, reliability, availability, or completeness of any information on the Site. Under no circumstances shall we have any liability to you for loss or damage of any kind incurred as a result of the use of the Site or reliance on any information provided on the Site. Your use of the Site and your reliance on any information on this Site is solely at your own risk.</p>"
  },
  affiliateDisclaimer: {
    title: "Affiliate Disclaimer",
    body: "<p class='small'>The content provided on this website is for educational purposes only and is not a substitute for professional medical advice, diagnosis, or treatment. Always consult your doctor or other qualified healthcare providers with any questions regarding your health or any medical condition.</p><h6>External Link Disclaimer</h6><p class='small'>The Site may contain links to other websites or content belonging to or originating from third parties or links to websites or features to banners or other advertising. Such external links are not investigated, monitored, or checked for accuracy, adequacy, validity, reliability, availability, or completeness by us. We do not warrant, endorse, guarantee, or assume responsibility for the accuracy or reliability of any information offered by third-party websites linked through the Site or any website or featured linked in any banner or other advertising. We will not be party to or in any way be responsible for monitoring any transaction between you and third-party providers of products or services.</p><h6>Professional Disclaimer</h6><p class='small'>This Site cannot and does not contain any medical/health advice. The medical/health information is provided for general information and educational purposes only and is not a substitute for professional advice. Accordingly, we encourage you to consult with the appropriate professionals before taking any action based upon such information. We do not provide any medical/health advice. The use or reliance of any information on this Site is solely at your own risk.</p>"
  },
  resourcesDisclaimer: {
    title: "Resources Disclaimer",
    body: "<p class='small'>The content provided on this website is for educational purposes only and is not a substitute for professional medical advice, diagnosis, or treatment. Always consult your doctor or other qualified healthcare providers with any questions regarding your health or any medical condition.</p>"
  },
}

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})

export class FooterComponent implements OnInit {
  iconPhone = faPhone;
  iconAddress = faMapPin;
  iconEmail = faEnvelope;

  modalRef: any;
  modalContent: any;

  constructor(private modalService: NgbModal) { }

  ngOnInit(): void {
  }

  openModal(content, disclaimerType): void {
    this.modalRef = this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', centered: true, size: "lg" });
    this.modalContent = disclaimers[disclaimerType];
  }

  closeModal() {
    this.modalRef.close();
  }
}
