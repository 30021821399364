<div class="dropdown">
  <button class="select-trigger" (click)="open(dropdown, origin)" #origin>
    <ng-template style="z-index:100" [ngIf]="!isOpen || isOpen">
      {{ label }}
      <span
        class="arrow"
        (click)="$event.stopPropagation()"
        [ngClass]="!isOpen ? 'arrowDown' : 'arrowUp'"
      ></span>
    </ng-template>

    <ng-template #searchTpl>
      <input
        [formControl]="searchControl"
        autofocus
        (click)="$event.stopPropagation()"
      />
    </ng-template>
  </button>
  <ng-template #dropdown>
    <div class="select-menu">
      <cdk-virtual-scroll-viewport
        itemSize="32"
        class="select-menu-inner"
        [style.height.px]="visibleOptions * 58"
      >
        <div *ngIf="!options.length" class="no-results-item">
          No results found...
        </div>

        <div
          *cdkVirtualFor="let option of options"
          class="select-item"
          [class.active]="isActive(option)"
          (click)="select(option)"
        >
          <ng-template [ngIf]="!optionTpl">{{ option.label }}</ng-template>
          <ng-template
            *ngTemplateOutlet="optionTpl; context: { $implicit: option }"
          ></ng-template>
        </div>
      </cdk-virtual-scroll-viewport>
    </div>
  </ng-template>
</div>
