<mat-card class="form-container">
  <div *ngIf="isFormHeader">
    <h2>Contact the HeRe Team</h2>
    <img class="divider" src="../../../assets/img/divider.png" />
    <p class="contactus-st small">
      We are listening. Please reach out to us using the form or email <a
      href="mailto:HeReforWomen@memorialhermann.org?subject=HeRe - Contact Us">HeReforWomen@memorialhermann.org</a> if you have any questions, comments, and/or resource suggestions.
    </p>
  </div>
  <form [formGroup]="ContactUsForm" (ngSubmit)="onSubmit()">
    <mat-form-field appearance="outline">
      <mat-label>Full Name - <em>Optional</em></mat-label>
      <input matInput [formControl]="ContactUsForm.controls.name" placeholder="Example: Dana Foster">
      <mat-icon matSuffix>person</mat-icon>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Subject <em>Optional</em></mat-label>
      <mat-select [formControl]="ContactUsForm.controls.subject">
        <mat-option *ngFor="let option of dropdownOptions" [value]="option">
          {{option}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Email - <em>Optional</em></mat-label>
      <input matInput [formControl]="ContactUsForm.controls.email" placeholder="Example: dana_foster@gmail.com">
      <mat-icon matSuffix>email</mat-icon>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Message
      </mat-label>
      <textarea matInput type="text" class="form-control" id="message"
        [formControl]="ContactUsForm.controls.message" maxlength="500"></textarea>
    </mat-form-field>

    <button [disabled]="
        !ContactUsForm.valid
      " class="btn btn-primary" type="submit">
      Submit
    </button>
  </form>
</mat-card>