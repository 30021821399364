import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import {
  faUserCircle,
  faEnvelope,
  faAddressBook,
  faAt,
  faCaretUp,
  faCaretDown
} from '@fortawesome/free-solid-svg-icons';
import { ResoucesService } from 'src/app/services/resouces.service';

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss']
})
export class ContactFormComponent implements OnInit {
  iconSubject = faAddressBook;
  iconUserCircle = faUserCircle;
  iconEnvelope = faEnvelope;
  iconAt = faAt;
  iconCaretDown = faCaretDown;
  iconCaretUp = faCaretUp;

  isShowDropdown: boolean = false;

  ContactUsForm: FormGroup;
  dropdownOptions: string[] = [
    'Question',
    'Resource suggestion',
    'Comment',
    'Site Problem'
  ];

  zipCode: string = '';

  @Input() isFormHeader: boolean = true;

  constructor(private snackBar: MatSnackBar, private resourceService: ResoucesService) {
    this.setContactForm();
  }

  ngOnInit(): void {
  }

  setContactForm() {
    this.ContactUsForm = new FormGroup({
      name: new FormControl(null),
      subject: new FormControl('Comment', [Validators.required]),
      email: new FormControl(null),
      message: new FormControl(null, [Validators.required])
    });
  }

  async onSubmit() {
    const body = {
      name: this.ContactUsForm.controls.name.value,
      formType: 'Contact Us Form',
      requestType: this.ContactUsForm.controls.subject.value,
      fromEmail: this.ContactUsForm.controls.email.value || null,
      emailMsg: this.ContactUsForm.controls.message.value
    }

    const emailRes = await this.resourceService.sendEmail(body);

    this.openSnackBar(emailRes.successful);

    if (emailRes.successful) {
      Object.keys(this.ContactUsForm.controls).forEach((key) => {
        const control = this.ContactUsForm.controls[key];
        control.markAsPristine();
        control.markAsUntouched();
        control.clearValidators();
      });
      this.ContactUsForm.reset();
    }

  }

  openSnackBar(isSuccess) {
    this.snackBar.open(`${isSuccess ? 'Your message has been successfully submitted.' : "We're sorry. There was a problem sending your message. Please try again."}`,
      "", {
      horizontalPosition: 'right',
      verticalPosition: 'top',
      duration: 3 * 1000,
      panelClass: isSuccess ? "red-text" : ""
    })
  }

  onSelectSubject(subject) {
    this.ContactUsForm.controls.subject.setValue(subject.value);
  }
}
