<!-- <div class="sort-dropdown">
  <span class="sort-label">Sort By:</span>
  <div (mousedown)="showDropdown = !showDropdown" class="sort-input">
    <select readonly>
      <span class="arrowDown"></span>
      </div> -->
<!-- <div class="dropdown-menu">
        <span *ngFor="let option of sortOPtions">{{ option }}</span>
      </div></select
    >
  </div>

  <div class="form-group">
    <label for="sortBy">Sort By:</label>
    <select formControlName="sortBy">
      <option
        *ngFor="let option of sortOPtions"
        (change)="changeSortOption($event)"
        >{{ option }}</option
      >
    </select>
  </div>
</div> -->
<!-- (change)="changeWebsite($event)" -->
<ng-template #dropdown>
  <div class="select-menu">
    <cdk-virtual-scroll-viewport itemSize="32">
      <div *ngIf="!options.length">No results found...</div>

      <div
        *cdkVirtualFor="let option of options"
        [class.active]="isActive(option)"
        (click)="select(option)"
      >
        {{ option.label }}
      </div>
    </cdk-virtual-scroll-viewport>
  </div>
</ng-template>
