import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators, FormGroupDirective } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  faUserCircle,
  faEnvelope,
  faAddressBook,
  faAt,
  faCaretUp,
  faCaretDown
} from '@fortawesome/free-solid-svg-icons';
import { take } from 'rxjs/operators';
import { ResoucesService } from 'src/app/services/resouces.service';

@Component({
  selector: 'app-join-newsletter-form',
  templateUrl: './join-newsletter-form.component.html',
  styleUrls: ['./join-newsletter-form.component.scss']
})
export class JoinNewsletterFormComponent implements OnInit {
  JoinNewsletterForm: FormGroup;
  iconUser = faUserCircle;
  iconEnvelope = faEnvelope;
  iconSubjeect = faAddressBook;
  iconAt = faAt;

  iconCaretDown = faCaretDown;
  iconCaretUp = faCaretUp;

  isShowDropdown: boolean = false;

  isOtherIdentity: boolean = false;

  formSumitAttempt: boolean = false;

  @ViewChild(FormGroupDirective) formGroupDirective: FormGroupDirective;

  dropdownOptions: string[] = [
    'Individual with SCI',
    'Health Professional',
    'Researcher',
    'Professor',
    'Caregiver of an individual with SCI',
    'Family member of an individual with SCI',
    'Advocate',
    'Other'
  ];

  constructor(private snackBar: MatSnackBar, private resourceService: ResoucesService) { }

  ngOnInit(): void {
    this.setJoinNewsletterForm();

    this.JoinNewsletterForm.controls.identity.valueChanges.subscribe(value => {
      if (value)
        this.isOtherIdentity = value.includes("Other");
    })
  }

  setJoinNewsletterForm() {
    this.JoinNewsletterForm = new FormGroup({
      name: new FormControl("", [Validators.required]),
      lastName: new FormControl("", [Validators.required]),
      title: new FormControl(""),
      identity: new FormControl("", [Validators.required]),
      otherIdentity: new FormControl(""),
      affiliation: new FormControl(""),
      interest: new FormControl(""),
      email: new FormControl("", [Validators.required]),
      consentIdentity: new FormControl(false),
      consentEmails: new FormControl(false, [Validators.requiredTrue])
    });

    Object.keys(this.JoinNewsletterForm.controls).forEach((key) => {
      const control = this.JoinNewsletterForm.controls[key];
      console.log(control)
    });
  }

  isFieldValid(field: string) {
    return (
      (this.JoinNewsletterForm.get(field).untouched && this.formSumitAttempt)
    )
  }

  openSnackBar(isSuccess) {
    this.snackBar.open(`${isSuccess ? 'Your message has been successfully submitted.' : "We're sorry. There was a problem sending your message. Please try again."}`,
      "", {
      horizontalPosition: 'right',
      verticalPosition: 'top',
      duration: 3 * 1000,
      panelClass: isSuccess ? "red-text" : ""
    })
  }

  onSelectAffiliate(option) {
    this.JoinNewsletterForm.controls.identity.setValue(option);
  }

  async onSubmit($event) {
    $event.preventDefault();
    $event.stopPropagation();

    const body = {
      name: `${this.JoinNewsletterForm.controls.name.value} ${this.JoinNewsletterForm.controls.lastName.value}`,
      formType: 'Join Us Form',
      nameTitle: this.JoinNewsletterForm.controls.title.value || null,
      fromEmail: this.JoinNewsletterForm.controls.email.value,
      identity: this.JoinNewsletterForm.controls.identity.value,
      otherIdentity: this.JoinNewsletterForm.controls.otherIdentity.value || null,
      affiliation: this.JoinNewsletterForm.controls.affiliation.value || null,
      interest: this.JoinNewsletterForm.controls.interest.value || null,
      consentIdentity: this.JoinNewsletterForm.controls.consentIdentity.value,
      consentEmails: this.JoinNewsletterForm.controls.consentEmails.value,
    }

    const emailRes = await this.resourceService.sendEmail(body);
    this.openSnackBar(emailRes.successful);

    if (emailRes.successful) {
      Object.keys(this.JoinNewsletterForm.controls).forEach((key) => {
        const control = this.JoinNewsletterForm.controls[key];
        control.markAsPristine();
        control.markAsUntouched();
        control.clearValidators();
      });

      this.JoinNewsletterForm.reset();
    }
  }
}
